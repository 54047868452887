import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';

import { listQueryKeyFactory } from 'data-fetching/utils';
import { ApiURLs, fetchURL } from 'services/requests-base';

const fetchChannel = async (public_id: string) => {
  const url = ApiURLs['channels_api:retrieve_update_destroy']({ public_id });
  const { data: response } = await fetchURL(url);
  return response;
};

export const channels = createQueryKeys('channels', {
  list: listQueryKeyFactory({ endpointName: 'channels_api:list_create' }),
  detail: (public_id: string) => ({
    queryKey: [public_id],
    queryFn: () => fetchChannel(public_id),
  }),
});

export type SegmentsQueryKeys = inferQueryKeys<typeof channels>;
