import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { useContentOnSubmitSuccessHandler } from 'catalog/hooks';
import { queries } from 'queries';
import { isEmbeddedVideoUrl } from 'services/embed';
import PageTitle from 'shared/components/PageTitle/PageTitle';
import { useCurrentUser } from 'shared/hooks';
import { sortCustomTags } from 'topics/services';
import { get, has, omit, slice } from 'vendor/lodash';
import VideoForm from 'video/components/VideoForm/VideoForm';

const getInitialValues = ({ currentUser, defaultSettings, clonedVideo, video }) => {
  let initialValues = {
    ...video,
  };

  if (clonedVideo) {
    const dataToClone = omit(clonedVideo, ['public_id', 'public_id_and_slug', 'video_url']);
    // API2.0 returns video_url
    const videoLink = get(clonedVideo, 'video_url') || get(clonedVideo, 'link');

    initialValues = {
      ...dataToClone,
      name: `Duplicate of ${clonedVideo.name}`,
      link: videoLink,
    };
  }

  // Facilitators
  if (!has(initialValues, 'facilitators_ids')) {
    initialValues.facilitators_ids = [currentUser.id];
  }

  // Access control
  if (!has(initialValues, 'is_hidden')) {
    initialValues.is_hidden = false;
  }

  // If is_completable is not defined, set it as initially true
  if (!has(initialValues, 'is_completable')) {
    initialValues.is_completable = true;
  }

  // Settings
  if (!has(initialValues, 'settings')) {
    initialValues.settings = defaultSettings;
  }

  const customTagsInitialValues = sortCustomTags(currentUser, initialValues, 'tags');

  if (isEmbeddedVideoUrl(initialValues.link, currentUser.allowed_video_url_pattern)) {
    const durationAsSeconds = moment.duration(initialValues.duration).asSeconds();
    initialValues.duration = moment.utc(durationAsSeconds * 1000).format('HH:mm:ss');
  }

  return { ...initialValues, ...customTagsInitialValues };
};

const VideoFormPage = ({
  pageTitle,
  topBarActionName,
  backRoute,
  breadcrumbsItemList,
  initialValuesParams,
  initialValuesParams: { video },
}) => {
  const currentUser = useCurrentUser();

  const queryClient = useQueryClient();

  const handleInvalidateVideoQuery = () => {
    const publicId = get(video, 'public_id');
    if (!publicId) {
      return;
    }

    let queryKey = queries.videos.detail(publicId).queryKey;
    // Last element is view mode, we don't need it
    queryKey = slice(queryKey, 0, -1);
    queryClient.invalidateQueries({ queryKey });
  };

  const { handleContentOnSubmitSuccessHandler } = useContentOnSubmitSuccessHandler();

  const initialValues = getInitialValues({
    currentUser,
    ...initialValuesParams,
  });

  const isEdit = Boolean(video);
  const form = `newVideo${isEdit ? 'Edit' : 'Create'}Form`;

  return (
    <>
      <PageTitle title={pageTitle} />
      <VideoForm
        form={form}
        initialValues={initialValues}
        topBarActionName={topBarActionName}
        isEdit={isEdit}
        backRoute={backRoute}
        breadcrumbsItemList={breadcrumbsItemList}
        onSubmitSuccessHandler={(result) => {
          if (isEdit) {
            handleInvalidateVideoQuery();
          }

          handleContentOnSubmitSuccessHandler({
            backRoute,
            isEdit,
            result,
          });
        }}
      />
    </>
  );
};

export default VideoFormPage;

VideoFormPage.defaultProps = {
  initialValuesParams: {},
};

VideoFormPage.propTypes = {
  pageTitle: PropTypes.string,
  topBarActionName: PropTypes.string,
  backRoute: PropTypes.string,
  breadcrumbsItemList: PropTypes.arrayOf(PropTypes.object),
  initialValuesParams: PropTypes.object,
};
