import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { useParentSettings } from 'app/settings/hooks';
import { LEARNING_TYPES } from 'catalog/constants';
import { queries } from 'queries';
import { mapRoute } from 'services/requests';
import Loading from 'shared/components/Loading';
import { useOriginRoute, useQueryParams, useSetDetailedObject } from 'shared/hooks';
import { isEmpty, toLower } from 'vendor/lodash';

import VideoFormPage from './VideoFormPage';

const labelVideo = 'Video';
const labelVideoPlural = 'Videos';

const VideoCreate = () => {
  const parentSettingsFlat = useParentSettings('video/form', 'flat');

  const { cloned: duplicatedVideoId } = useQueryParams();
  const isDuplicating = Boolean(duplicatedVideoId);

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.videos
  }&o=upcoming`;

  const backRoute = useOriginRoute(catalogRoute);

  const { data: clonedVideo, isLoading: isLoadingVideoToClone } = useQuery({
    ...queries.videos.detail(duplicatedVideoId || '', 'detail'),
    retry: 0,
    enabled: isDuplicating,
  });

  useSetDetailedObject({ id: null }, 'videos', []);

  if (isDuplicating && isLoadingVideoToClone) {
    return <Loading />;
  }

  const initialValuesParams = {};

  if (duplicatedVideoId) {
    initialValuesParams.clonedVideo = clonedVideo;
  } else if (!isEmpty(parentSettingsFlat)) {
    initialValuesParams.defaultSettings = parentSettingsFlat;
  }

  return (
    <VideoFormPage
      pageTitle={`${isDuplicating ? 'Duplicate' : 'Create'} ${labelVideo}`}
      topBarActionName={`${isDuplicating ? 'Duplicating' : 'Creating'} ${labelVideo}`}
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: labelVideoPlural,
          link: catalogRoute,
        },
        {
          label: `New ${toLower(labelVideo)}`,
        },
      ]}
    />
  );
};

export default VideoCreate;
