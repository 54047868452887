import { useQuery } from '@tanstack/react-query';
import { Field } from 'redux-form';

import AutocompleteField from 'app/inputs/components/AutocompleteField';
import { useLabels } from 'app/shared/hooks';
import { queries } from 'queries';
import { FormFieldGroup } from 'shared/components/Form';
import InfoText from 'shared/components/InfoText';
import Form from 'shared/components/OldForm';
import { map } from 'vendor/lodash';

const validateRequired = Form.validations.required();

const makeOptions = (data) => {
  return map(data.results, (row) => ({
    value: row.value,
    label: row.name,
  }));
};

const ChannelSelectFieldComponent = (props) => {
  const { data: channels, isLoading } = useQuery({
    ...queries.channels.list('view_mode=filter_options'),
    select: makeOptions,
  });

  return <AutocompleteField options={channels} loading={isLoading} {...props} />;
};

const ChannelSelectField = () => {
  const { label_channel: labelChannel } = useLabels();

  return (
    <FormFieldGroup>
      <InfoText top={1} bottom={16} content="Choose this item's Channel." />

      <span data-testid="channel-field">
        <Field
          label={labelChannel}
          name="channel_id"
          required
          multiple={false}
          component={ChannelSelectFieldComponent}
          validate={[validateRequired]}
        />
      </span>

      <InfoText
        top={4}
        bottom={4}
        content="If the default channel is selected this new item will be available for every user."
      />
    </FormFieldGroup>
  );
};

export default ChannelSelectField;
