import { ReactNode } from 'react';

import { Box } from 'vendor/mui';

interface Props {
  children?: ReactNode;
}

const CardBody = ({ children }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 194,
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 0,
      }}
    >
      {children}
    </Box>
  );
};

export default CardBody;
