import { useHistory } from 'react-router';

import { useLabels } from 'app/shared/hooks';
import { useDeleteChannel } from 'features/channels/api/mutations';
import { toast } from 'notifications/components/NotificationCenter';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import { Stack, Typography } from 'vendor/mui';

import { Channel } from '../types';

interface ChannelDeleteModalProps {
  channel: Channel;
  handleClose: () => void;
}

const ChannelDeleteModal = ({ channel, handleClose }: ChannelDeleteModalProps) => {
  const history = useHistory();
  const { label_channel: labelChannel } = useLabels();

  const deleteChannel = useDeleteChannel(channel.public_id);

  const handleDeleteClick = async () => {
    try {
      await deleteChannel.mutateAsync();
      history.push(mapRoute('unifiedCatalogList'));

      handleClose();
      toast.success(`${labelChannel} deleted successfully`);
    } catch (error) {
      toast.error('Error', 'Please try again later');
    }
  };

  return (
    <Modal
      title={`Delete "${channel.name}"`}
      handleClose={handleClose}
      maxWidth={window.innerWidth > 600 ? 600 : null}
      minBodyHeight="124px"
    >
      <ModalBody>
        <Stack spacing={2}>
          <Typography variant="body2">
            You are about to delete <b>&quot;{channel.name}&quot;</b>. Once deleted, you will no
            longer be able to access this {labelChannel}, nor will you be able to restore it.
          </Typography>

          <Typography variant="body2">
            Are you sure you want to proceed? This action cannot be undone.
          </Typography>
        </Stack>
      </ModalBody>

      <ModalFooter variant="buttons">
        <ModalFooterButton variant="text" onClick={handleClose} sx={{ color: colors.neutral600 }}>
          Cancel
        </ModalFooterButton>
        <ModalFooterButton color="error" onClick={handleDeleteClick} aria-label="Delete">
          Delete
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

export default ChannelDeleteModal;
