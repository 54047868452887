import { PAST_SHORTCUT } from 'app/inputs/components/FlexibleDateRangeInput/DateRangeShortcuts';
import { rqlToInput } from 'backoffice/components/Dashboard/Filters/utils';
import { ATTENDANCE_METHODS, ATTENDANCE_METHODS_LABELS } from 'enrollments/constants';
import { FilterDef } from 'filters/components/RQLFilterBar/RQLFilterBar';
import { useGetAllCachedOptions } from 'filters/hooks';
import { FiltersDefinition, FiltersDefinitionProps, PillsDefinitionProps } from 'filters/types';
import {
  dateRangeInputToDateRangeRQL,
  dateRangeRQLToDateRangeInput,
  getDateRangeDateRangeInputFormat,
  getDateRangeText,
  getFlexibleFiltersDef,
  getOrderedFiltersDefinition,
} from 'filters/utils';
import { RQLDateRange } from 'shared/components/types';
import { useCurrentUser } from 'shared/hooks';
import {
  get,
  toString,
  reduce,
  sortBy,
  filter,
  keys,
  includes,
  map,
  concat,
  set,
} from 'vendor/lodash';
import { useMediaQuery } from 'vendor/mui';

import { EVENT_STATUSES, EVENT_STATUSES_LABELS } from './constants';

export const useGetAllEventFiltersDefinition = ({
  filters,
  ordering,
  updateFilter,
  setOrdering,
}: FiltersDefinitionProps): Record<string, FilterDef> => {
  const currentUser = useCurrentUser();

  const { startDate, endDate } = dateRangeRQLToDateRangeInput(
    get(filters, 'start_time', null) as RQLDateRange | null
  );
  const dateRangeFormat = getDateRangeDateRangeInputFormat({ startDate, endDate }) || 'relative';
  const dateMessage = getDateRangeText({ startDate, endDate, format: dateRangeFormat });
  const isUpcomingFilterOn = dateMessage === 'Upcoming';

  return {
    SearchBar: {
      type: 'search_bar',
      placeholder: 'Search by names, tags, locations...',
      value: get(filters, 'q', null),
      onChange: (newValue) => updateFilter({ q: newValue }),
      gridProps: {
        xs: true,
      },
      width: '100%',
    },
    Attendance: {
      type: 'select',
      label: 'Attendance',
      value: get(filters, 'attendance_method', null),
      options: [
        { name: 'Online', value: 'online' },
        { name: 'In Person', value: 'local' },
      ],
      onChange: (newValue) => updateFilter({ attendance_method: newValue }),
    },
    Categories: {
      type: 'model_select',
      label: 'Categories',
      value: get(filters, 'category', null),
      multiple: true,
      sortOptions: true,
      queryName: 'tags',
      queryParams: { tag_type: 'main_topics' },
      onChange: (newValue) => updateFilter({ category: newValue }),
    },
    Sort: {
      type: 'select',
      label: 'Sort by',
      value: { $eq: ordering },
      options: [
        { name: 'Date', value: 'start_time' },
        { name: 'Newest', value: '-created' },
        { name: 'Most Engaged', value: '-total_assignments' },
        { name: 'Best Rated', value: '-avg_feedback_rating' },
        { name: 'A-Z', value: 'name' },
        { name: 'Z-A', value: '-name' },
      ],
      width: '100%',
      gridProps: {
        xs: true,
        lg: 2,
      },
      onChange: (newValue) => setOrdering(get(newValue, '$eq', 'start_time') as string),
    },
    Availability: {
      type: 'boolean',
      label: 'Available spots',
      value: get(filters, 'has_available_spots', null),
      onChange: (newValue) => updateFilter({ has_available_spots: newValue }),
    },
    'Friendly To My Time': {
      type: 'boolean',
      label: 'Friendly To My Time',
      value: get(filters, 'is_friendly_to_my_time', null),
      onChange: (newValue) => updateFilter({ is_friendly_to_my_time: newValue }),
    },
    Ownership: {
      type: 'model_select',
      label: 'Ownership',
      value: get(filters, 'facilitator', null),
      multiple: true,
      sortOptions: true,
      sortByOption: 'display_name',
      queryName: 'old_users',
      getOptionLabel: (option) =>
        get(option, 'display_name', option.name || toString(option.value)),
      onChange: (newValue) => updateFilter({ facilitator: newValue }),
    },
    Status: {
      type: 'select',
      label: 'Status',
      value: get(filters, 'status', null),
      options: [
        { name: 'Published', value: 'published' },
        { name: 'Cancelled', value: 'archived' },
      ],
      onChange: (newValue) => updateFilter({ status: newValue }),
    },
    Dates: {
      type: 'date_range',
      label: 'Dates',
      value: get(filters, 'start_time', null),
      onChange: (newValue) => updateFilter({ start_time: newValue }),
    },
    Location: {
      type: 'model_select',
      label: 'Location',
      value: get(filters, 'location', null),
      multiple: true,
      sortOptions: true,
      queryName: 'locations',
      queryParams: { o: 'name' },
      onChange: (newValue) => updateFilter({ location: newValue }),
    },
    'Event type': {
      type: 'model_select',
      label: 'Event type',
      value: get(filters, 'event_type', null),
      multiple: true,
      sortOptions: true,
      queryName: 'event_types',
      queryParams: { o: 'name' },
      onChange: (newValue) => updateFilter({ event_type: newValue }),
    },
    Tags: {
      type: 'model_select',
      label: 'Tags',
      value: get(filters, 'tag', null),
      multiple: true,
      sortOptions: true,
      queryName: 'tags',
      onChange: (newValue) => updateFilter({ tag: newValue }),
    },
    'My Events': {
      type: 'boolean',
      label: 'My events',
      value: get(filters, 'my_events', null),
      onChange: (newValue) => updateFilter({ my_events: newValue }),
    },
    Upcoming: {
      type: 'boolean',
      label: 'Upcoming',
      value: isUpcomingFilterOn ? { $eq: 'true' } : null,
      onChange: (newValue) => {
        if (newValue) {
          updateFilter({
            start_time: dateRangeInputToDateRangeRQL({ startDate: '-PT0H', endDate: '' }),
          });
        } else {
          updateFilter({
            start_time: null,
          });
        }
      },
    },
    ...reduce(
      getFlexibleFiltersDef({
        filters,
        updateFilter,
        currentUser,
        toggleTypes: ['toggle_events'],
      }),
      (acc, filterDef) => ({ ...acc, [toString(filterDef.filterName)]: filterDef }),
      {}
    ),
  };
};

export const useEventsFiltersDefinition = ({
  filters,
  ordering,
  updateFilter,
  setOrdering,
  fixedFilters,
}: FiltersDefinitionProps): FiltersDefinition => {
  const isScreenMdDown = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const currentUser = useCurrentUser();

  const defaultFiltersOrder = [
    'SearchBar',
    'Attendance',
    'Categories',
    'Sort',
    'Availability',
    'Friendly To My Time',
    'Tags',
    'Status',
    'Dates',
  ];

  const allFilters: Record<string, FilterDef> = useGetAllEventFiltersDefinition({
    filters,
    ordering,
    updateFilter,
    setOrdering,
  });

  // Hide "Upcoming" date shortcut for events page
  const datesFilterDef = get(allFilters, 'Dates');
  if (datesFilterDef) {
    set(datesFilterDef, 'extraProps.shortcuts', [PAST_SHORTCUT]);
  }

  return getOrderedFiltersDefinition({
    currentUser,
    filterOrderName: 'filter_order_events',
    defaultFiltersOrder,
    allFilters,
    filterWidth: '100%',
    fixedFilters,
    numberOfFiltersShownOutside: isScreenMdDown ? 0 : 2,
  });
};

export const useEventsPillsDefinition = ({ filters }: PillsDefinitionProps) => {
  // Cached options
  const allTags = useGetAllCachedOptions({ keyPrefix: 'tags' });
  const allUsers = useGetAllCachedOptions({ keyPrefix: 'old_users' });
  const allEventTypes = useGetAllCachedOptions({ keyPrefix: 'event_types' });
  const allLocations = useGetAllCachedOptions({ keyPrefix: 'locations', key: 'list' });
  // Values
  const searchValue = get(filters, 'q.$eq', '');
  const selectedAttendanceMethod = get(rqlToInput(get(filters, 'attendance_method')), '0', null);
  const hasAvailableSpots =
    get(rqlToInput(get(filters, 'has_available_spots')), '0', null) === 'true';
  const isFriendlyToMyTime =
    get(rqlToInput(get(filters, 'is_friendly_to_my_time')), '0', null) === 'true';
  const selectedCategories = rqlToInput(get(filters, 'category'));
  const selectedFacilitators = rqlToInput(get(filters, 'facilitator'));
  const selectedStatus = get(rqlToInput(get(filters, 'status')), '0', null);
  // Dates
  const { startDate, endDate } = dateRangeRQLToDateRangeInput(
    get(filters, 'start_time', null) as RQLDateRange | null
  );
  const format = getDateRangeDateRangeInputFormat({ startDate, endDate }) || 'relative';
  const dateMessage = getDateRangeText({ startDate, endDate, format });
  const selectedEventTypes = rqlToInput(get(filters, 'event_type'));
  const selectedLocations = rqlToInput(get(filters, 'location'));
  const myEvents = get(rqlToInput(get(filters, 'my_events')), '0', null) === 'true';
  // Tags and flexible filters
  const otherFilters = sortBy(
    filter(
      keys(filters),
      (key: string) =>
        !includes(
          [
            'q',
            'attendance_method',
            'has_available_spots',
            'is_friendly_to_my_time',
            'category',
            'facilitator',
            'status',
            'dates',
            'event_type',
            'location',
            'start_time',
            'view_density',
            'my_events',
            // deprecated
            'period',
            'starts_after',
            'starts_before',
          ],
          key
        )
    )
  );
  return {
    pills: [
      ...(startDate || endDate
        ? [
            {
              id: 'start-time-{startDate}-{endDate}',
              value: null,
              label: dateMessage,
              icon: 'calendar',
              filterName: 'start_time',
            },
          ]
        : []),
      ...(searchValue
        ? [
            {
              id: `search-${searchValue}`,
              value: searchValue,
              label: searchValue,
              icon: 'search',
              filterName: 'q',
            },
          ]
        : []),
      ...(myEvents
        ? [
            {
              id: 'my-events',
              value: 'true',
              label: 'My Events',
              filterName: 'my_events',
            },
          ]
        : []),
      ...(selectedAttendanceMethod
        ? [
            {
              id: `attendance-method-${selectedAttendanceMethod}`,
              value: selectedAttendanceMethod,
              label: ATTENDANCE_METHODS_LABELS[selectedAttendanceMethod],
              icon: selectedAttendanceMethod === ATTENDANCE_METHODS.local ? 'location' : 'online',
              filterName: 'attendance_method',
            },
          ]
        : []),
      ...map(selectedCategories, (value: string) => ({
        id: `category-${value}`,
        value: value,
        label: get(allTags, value, { name: value }).name,
        icon: 'hashtag',
        filterName: 'category',
      })),
      ...(hasAvailableSpots
        ? [
            {
              id: 'has-available-spots',
              value: 'true',
              label: 'Available Spots',
              filterName: 'has_available_spots',
            },
          ]
        : []),
      ...(isFriendlyToMyTime
        ? [
            {
              id: 'is-friendly-to-my-time',
              value: 'true',
              label: 'Friendly To My Time',
              filterName: 'is_friendly_to_my_time',
            },
          ]
        : []),
      ...map(selectedFacilitators, (value: string) => ({
        id: `user-${value}`,
        value: value,
        label: get(allUsers, value, { display_name: value }).display_name,
        imageSrc: get(allUsers, value, { profile_image: null }).profile_image,
        filterName: 'facilitator',
      })),
      ...(selectedStatus
        ? [
            {
              id: `status-${selectedStatus}`,
              value: selectedStatus,
              label: EVENT_STATUSES_LABELS[selectedStatus],
              icon: selectedStatus === EVENT_STATUSES.archived ? 'archived' : '',
              filterName: 'status',
            },
          ]
        : []),
      ...map(selectedEventTypes, (value: string) => ({
        id: `event-type-${value}`,
        value: value,
        label: get(allEventTypes, value, { name: value }).name,
        icon: 'calendar',
        filterName: 'event_type',
      })),
      ...map(selectedLocations, (value: string) => ({
        id: `location-${value}`,
        value: value,
        label: get(allLocations, value, { name: value }).name,
        icon: 'location',
        filterName: 'location',
      })),
      ...concat(
        [],
        ...map(otherFilters, (filterName: string) =>
          map(rqlToInput(get(filters, filterName)), (value: string) => ({
            id: `${filterName}-${value}`,
            value: value,
            label: get(allTags, value, { name: value }).name,
            icon: 'tag',
            filterName,
          }))
        )
      ),
    ],
  };
};
