// TODO merge this component with `FeedbackContainer` after we fully transform events in a `ContentItem`
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React from 'react';

import { shouldDisplayFeedbackComponent } from 'event-shared/services';
import { queries } from 'queries';
import { useToggles } from 'shared/hooks';
import BaseFeedbackContainer from 'stand-alone-shared/components/FeedbackContainer/BaseFeedbackContainer';
import { get } from 'vendor/lodash';

const EventFeedbackContainer = (props) => {
  const { event, cardWidth, cardHeight } = props;

  const { enrollment: currentUserAssignment, has_passed: hasPassed } = event;

  const { toggle_display_feedback_if_survey_link: toggleDisplayFeedbackIfSurveyLink } =
    useToggles();

  const shouldDisplayFeedback = shouldDisplayFeedbackComponent(
    event,
    toggleDisplayFeedbackIfSurveyLink
  );

  const canDisplayFeedback = shouldDisplayFeedback && hasPassed;

  const { data: feedbackData, isLoading } = useQuery({
    ...queries.events.feedbackList(event.public_id),
    enabled: canDisplayFeedback,
  });

  if (!shouldDisplayFeedback || !hasPassed) {
    return null;
  }

  const feedbackList = get(feedbackData, 'results', []);

  return (
    <BaseFeedbackContainer
      containerTitle="Feedback for this event"
      content={event}
      feedbackList={feedbackList}
      isLoading={isLoading}
      currentUserAssignment={currentUserAssignment}
      cardHeight={cardHeight}
      cardWidth={cardWidth}
      withFlexBasis={false}
      mode="compact"
    />
  );
};

EventFeedbackContainer.propTypes = {
  event: PropTypes.object,
  cardWidth: PropTypes.number,
  cardHeight: PropTypes.number,
};

export default EventFeedbackContainer;
