import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { isUserPresenterOrOrganizer, shouldDisplayFeedbackComponent } from 'event-shared/services';
import { mapRoute } from 'services/requests';
import Button from 'shared/components/Button';
import RateButton from 'shared/components/RateButton';
import { get } from 'vendor/lodash';

const ButtonContainer = styled.div`
  margin-bottom: 16px;
`;

export const RatingButton = (props) => {
  const { averageRating, handleRateClick, viewMode, eventRoute } = props;
  const mainText = 'Rate this event';
  const rating = averageRating.toFixed(1);

  const areaContent = (
    <RateButton onClick={handleRateClick} rating={rating} fullWidth>
      {mainText}
    </RateButton>
  );

  return (
    <ButtonContainer viewMode={viewMode}>
      {viewMode === 'details' ? areaContent : <Link to={eventRoute}>{areaContent}</Link>}
    </ButtonContainer>
  );
};

RatingButton.propTypes = {
  averageRating: PropTypes.number,
  handleRateClick: PropTypes.func,
  viewMode: PropTypes.string,
  eventRoute: PropTypes.string,
};

const RatingArea = (props) => {
  const {
    event,
    onRateClick,
    viewMode,
    toggleDisplayFeedbackIfSurveyLink,
    labelCompleteSurvey,
    labelViewSurvey,
    currentUserId,
  } = props;

  const surveyLink = event.external_survey_link || event?.event_type?.external_survey_link;
  const shouldDisplay = shouldDisplayFeedbackComponent(event, toggleDisplayFeedbackIfSurveyLink);
  const displayRatingArea = !surveyLink || (surveyLink && shouldDisplay && viewMode === 'details');
  const labelSurvey = isUserPresenterOrOrganizer(currentUserId, event)
    ? labelViewSurvey
    : labelCompleteSurvey;

  return (
    <React.Fragment>
      {displayRatingArea && (
        <RatingButton
          averageRating={get(
            event,
            'average_feedback_rating',
            get(event, 'avg_feedback_rating', 0)
          )}
          handleRateClick={onRateClick}
          viewMode={viewMode}
          eventRoute={mapRoute('eventDetails', { public_id_and_slug: event.public_id_and_slug })}
        />
      )}
      {surveyLink && (
        <ButtonContainer>
          <Button alt="Click to complete survey" href={surveyLink} fullWidth target="_blank">
            {labelSurvey}
          </Button>
        </ButtonContainer>
      )}
    </React.Fragment>
  );
};

RatingArea.propTypes = {
  event: PropTypes.object,
  viewMode: PropTypes.string,
  onRateClick: PropTypes.func,

  toggleDisplayFeedbackIfSurveyLink: PropTypes.bool,
  labelCompleteSurvey: PropTypes.string,
  labelViewSurvey: PropTypes.string,
  currentUserId: PropTypes.number,
};

const mapStateToProps = (state) => ({
  currentUserId: state.user.currentUser.id,
  labelCompleteSurvey: state.user.currentUser.labels.label_complete_survey,
  labelViewSurvey: state.user.currentUser.labels.label_view_survey_for_organizers,
  toggleDisplayFeedbackIfSurveyLink:
    state.user.currentUser.toggles.toggle_display_feedback_if_survey_link,
});

export default connect(mapStateToProps, null)(RatingArea);
