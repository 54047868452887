import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  EnrollmentStatusPropType,
  PerformEnrollmentActionsPropType,
  SpotsStatusPropType,
} from 'event-shared/components/AttendanceActionManager/EnrollmentActionButton/propTypes';
import RequestEventModal from 'event-types/components/RequestEventModal';
import colors from 'services/colors';
import { getOrdinalNumber } from 'services/utils';
import Button from 'shared/components/Button';
import Clicker from 'shared/components/Clicker';
import DropDownMenu from 'shared/components/DropDownMenu';
import Icon from 'shared/components/Icon';
import Pill from 'shared/components/Pill';
import Text from 'shared/components/Text';
import { Tooltip } from 'shared/components/Tooltip';
import { useToggles, useTooltipUID } from 'shared/hooks';

const Container = styled.div`
  flex: 1 50%;
`;

const ActionContainer = styled.div`
  display: flex;
  margin-bottom: 16px;

  > * + * {
    margin-left: 10px;
  }
`;

const FullWidthDropDownMenu = styled(DropDownMenu)`
  flex: 1;
`;

const SwitchAttendanceContainer = styled.div`
  border-top: 1px solid ${colors.neutral200};

  // Cancel padding from parent
  margin: 0 -15px;
  padding: 16px 14px 16px 18px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SwitchAttendanceItem = styled(({ children, danger, ...props }) => (
  <Clicker fullWidth {...props}>
    <Text size="h4" color={danger ? colors.error600 : colors.neutral600}>
      {children}
    </Text>
  </Clicker>
))`
  background-color: ${colors.neutral0};
  padding: 6px 12px;

  display: flex;
  align-items: center;

  // This should be removed with the typography update
  line-height: 20px;

  &:focus,
  &:hover {
    background-color: ${colors.neutral100};
  }
`;

const SwitchAttendanceLabel = ({ children, danger }) => (
  <Text size="h4" medium color={danger ? colors.error600 : colors.action700}>
    {children}
  </Text>
);

SwitchAttendanceLabel.propTypes = {
  children: PropTypes.string,
  danger: PropTypes.bool,
};

export const CardAttendanceRenderEnrolled = ({
  actions: {
    performUnenroll,
    performLocalEnrollment,
    performOnlineEnrollment,
    performJoinLocalWaitlist,
    performJoinOnlineWaitlist,
  },
  status: {
    userIsEnrolledLocal,
    userIsEnrolledOnline,
    showLocalEnroll,
    showLocalWaitlist,
    showOnlineEnroll,
    showOnlineWaitlist,
  },
  spotStatus: { localWaitlistPretendedSpot, onlineWaitlistPretendedSpot },
}) => {
  const label = userIsEnrolledLocal ? `Enrolled In-Person` : `Enrolled Online`;

  const canSwitch =
    (showLocalEnroll && !userIsEnrolledLocal) ||
    (showLocalWaitlist && !userIsEnrolledLocal && !showLocalEnroll) ||
    (showOnlineEnroll && !userIsEnrolledOnline) ||
    (showOnlineWaitlist && !userIsEnrolledOnline && !showOnlineEnroll);

  if (!canSwitch) {
    return (
      <SwitchAttendanceContainer>
        <SwitchAttendanceLabel>{label}</SwitchAttendanceLabel>
        <Button size="small" color="error" onClick={performUnenroll}>
          Unenroll
        </Button>
      </SwitchAttendanceContainer>
    );
  }

  return (
    <SwitchAttendanceContainer>
      <SwitchAttendanceLabel>{label}</SwitchAttendanceLabel>
      <DropDownMenu
        renderButton={({ toggleMenu }) => (
          <Button size="small" onClick={toggleMenu}>
            Switch
          </Button>
        )}
      >
        {showLocalEnroll && !userIsEnrolledLocal && (
          <SwitchAttendanceItem onClick={performLocalEnrollment}>
            Switch to Enroll In-Person
          </SwitchAttendanceItem>
        )}
        {showOnlineEnroll && !userIsEnrolledOnline && (
          <SwitchAttendanceItem onClick={performOnlineEnrollment}>
            Switch to Enroll Online
          </SwitchAttendanceItem>
        )}
        {showLocalWaitlist && !showLocalEnroll && !userIsEnrolledLocal && (
          <SwitchAttendanceItem onClick={performJoinLocalWaitlist}>
            Switch to {getOrdinalNumber(localWaitlistPretendedSpot)} on In-Person Waitlist
          </SwitchAttendanceItem>
        )}
        {showOnlineWaitlist && !showOnlineEnroll && !userIsEnrolledOnline && (
          <SwitchAttendanceItem onClick={performJoinOnlineWaitlist}>
            Switch to {getOrdinalNumber(onlineWaitlistPretendedSpot)} on Online Waitlist
          </SwitchAttendanceItem>
        )}
        <SwitchAttendanceItem danger onClick={performUnenroll}>
          Unenroll {userIsEnrolledLocal ? 'In-Person' : 'Online'}
        </SwitchAttendanceItem>
      </DropDownMenu>
    </SwitchAttendanceContainer>
  );
};

CardAttendanceRenderEnrolled.propTypes = {
  actions: PerformEnrollmentActionsPropType,
  status: EnrollmentStatusPropType,
  spotStatus: SpotsStatusPropType,
};

export const CardAttendanceRenderWaitlist = ({
  actions: {
    performLeaveWaitlist,
    performLocalEnrollment,
    performOnlineEnrollment,
    performJoinLocalWaitlist,
    performJoinOnlineWaitlist,
  },
  status: {
    userIsOnLocalWaitlist,
    userIsOnOnlineWaitlist,
    showLocalEnroll,
    showLocalWaitlist,
    showOnlineEnroll,
    showOnlineWaitlist,
  },
  spotStatus: {
    localWaitlistCurrentSpot,
    onlineWaitlistCurrentSpot,
    localWaitlistPretendedSpot,
    onlineWaitlistPretendedSpot,
  },
}) => {
  const label = userIsOnLocalWaitlist
    ? `${getOrdinalNumber(localWaitlistCurrentSpot)} on In-Person Waitlist`
    : `${getOrdinalNumber(onlineWaitlistCurrentSpot)} on Online Waitlist`;

  const canSwitch =
    (showLocalEnroll && !userIsOnLocalWaitlist) ||
    (showLocalWaitlist && !userIsOnLocalWaitlist && !showLocalEnroll) ||
    (showOnlineEnroll && !userIsOnOnlineWaitlist) ||
    (showOnlineWaitlist && !userIsOnOnlineWaitlist && !showOnlineEnroll);

  if (!canSwitch) {
    return (
      <SwitchAttendanceContainer>
        <SwitchAttendanceLabel>{label}</SwitchAttendanceLabel>
        <Button size="small" color="error" onClick={performLeaveWaitlist}>
          Leave
        </Button>
      </SwitchAttendanceContainer>
    );
  }

  return (
    <SwitchAttendanceContainer>
      <SwitchAttendanceLabel>{label}</SwitchAttendanceLabel>
      <DropDownMenu
        renderButton={({ toggleMenu }) => (
          <Button size="small" onClick={toggleMenu}>
            Switch
          </Button>
        )}
      >
        {showLocalEnroll && !userIsOnLocalWaitlist && (
          <SwitchAttendanceItem onClick={performLocalEnrollment}>
            <Text size="h4">Switch to Enroll In-Person</Text>
          </SwitchAttendanceItem>
        )}
        {showOnlineEnroll && !userIsOnOnlineWaitlist && (
          <SwitchAttendanceItem onClick={performOnlineEnrollment}>
            Switch to Enroll Online
          </SwitchAttendanceItem>
        )}
        {showLocalWaitlist && !showLocalEnroll && !userIsOnLocalWaitlist && (
          <SwitchAttendanceItem onClick={performJoinLocalWaitlist}>
            Switch to {getOrdinalNumber(localWaitlistPretendedSpot)} on In-Person Waitlist
          </SwitchAttendanceItem>
        )}
        {showOnlineWaitlist && !showOnlineEnroll && !userIsOnOnlineWaitlist && (
          <SwitchAttendanceItem onClick={performJoinOnlineWaitlist}>
            Switch to {getOrdinalNumber(onlineWaitlistPretendedSpot)} on Online Waitlist
          </SwitchAttendanceItem>
        )}
        <SwitchAttendanceItem danger onClick={performLeaveWaitlist}>
          Leave {userIsOnLocalWaitlist ? 'In-Person' : 'Online'} Waitlist
        </SwitchAttendanceItem>
      </DropDownMenu>
    </SwitchAttendanceContainer>
  );
};

CardAttendanceRenderWaitlist.propTypes = {
  actions: PerformEnrollmentActionsPropType,
  status: EnrollmentStatusPropType,
  spotStatus: SpotsStatusPropType,
};

export const CardAttendanceRenderProspect = ({
  actions: {
    performLocalEnrollment,
    performOnlineEnrollment,
    performJoinLocalWaitlist,
    performJoinOnlineWaitlist,
  },
  status: { showLocalEnroll, showLocalWaitlist, showOnlineEnroll, showOnlineWaitlist },
  spotStatus: { localWaitlistPretendedSpot, onlineWaitlistPretendedSpot },
}) => {
  const hasLocalButton = showLocalEnroll || showLocalWaitlist;
  const hasOnlineButton = showOnlineEnroll || showOnlineWaitlist;

  const inPersonEnrollTooltip = useTooltipUID();
  const onlineEnrollTooltip = useTooltipUID();
  const inPersonWaitlistTooltip = useTooltipUID();
  const onlineWaitlistTooltip = useTooltipUID();

  if (showLocalWaitlist && showOnlineWaitlist && !showLocalEnroll && !showOnlineEnroll) {
    const localItem = (
      <SwitchAttendanceItem onClick={performJoinLocalWaitlist} key="localItem">
        {getOrdinalNumber(localWaitlistPretendedSpot)} on In-Person Waitlist
      </SwitchAttendanceItem>
    );
    const onlineItem = (
      <SwitchAttendanceItem onClick={performJoinOnlineWaitlist} key="onlineItem">
        {getOrdinalNumber(onlineWaitlistPretendedSpot)} on Online Waitlist
      </SwitchAttendanceItem>
    );

    return (
      <ActionContainer>
        <FullWidthDropDownMenu
          listFullWidth
          renderButton={({ toggleMenu }) => (
            <Container>
              <Button onClick={toggleMenu} color="secondary" size="large" fullWidth>
                Join Waitlist
              </Button>
            </Container>
          )}
        >
          {/* Less people on waitlist comes first */}
          {localWaitlistPretendedSpot < onlineWaitlistPretendedSpot
            ? [localItem, onlineItem]
            : [onlineItem, localItem]}
        </FullWidthDropDownMenu>
      </ActionContainer>
    );
  }

  return (
    <ActionContainer>
      {showLocalEnroll && (
        <Container>
          <Tooltip id={inPersonEnrollTooltip.uid}>Attend this event in-person</Tooltip>
          <Button
            {...inPersonEnrollTooltip.targetProps}
            onClick={performLocalEnrollment}
            startIcon={<Icon name="location" />}
            fullWidth
          >
            Enroll
          </Button>
        </Container>
      )}
      {showLocalWaitlist && !showLocalEnroll && (
        <Container>
          <Tooltip id={inPersonWaitlistTooltip.uid}>
            Join the waitlist for this in-person event
          </Tooltip>
          <Button
            {...inPersonWaitlistTooltip.targetProps}
            onClick={performJoinLocalWaitlist}
            color="secondary"
            fullWidth
            startIcon={<Icon name="location" />}
          >
            {hasOnlineButton ? 'Waitlist' : 'Join Waitlist'}
          </Button>
        </Container>
      )}
      {showOnlineEnroll && (
        <Container>
          <Tooltip id={onlineEnrollTooltip.uid}>Attend this event on-line</Tooltip>
          <Button
            {...onlineEnrollTooltip.targetProps}
            onClick={performOnlineEnrollment}
            fullWidth
            startIcon={<Icon name="online" />}
          >
            Enroll
          </Button>
        </Container>
      )}
      {showOnlineWaitlist && !showOnlineEnroll && (
        <Container>
          <Tooltip id={onlineWaitlistTooltip.uid}>Join the waitlist for this on-line event</Tooltip>
          <Button
            {...onlineWaitlistTooltip.targetProps}
            onClick={performJoinOnlineWaitlist}
            color="secondary"
            fullWidth
            startIcon={<Icon name="online" />}
          >
            {hasLocalButton ? 'Waitlist' : 'Join Waitlist'}
          </Button>
        </Container>
      )}
    </ActionContainer>
  );
};

CardAttendanceRenderProspect.propTypes = {
  actions: PerformEnrollmentActionsPropType,
  status: EnrollmentStatusPropType,
  spotStatus: SpotsStatusPropType,
};

export const CardAttendanceRenderUncancellableEnroll = ({ unenrollLabel }) => (
  <ActionContainer>
    <Container>
      <Button fullWidth disabled title="You are enrolled in the event and cancellation is closed">
        {unenrollLabel || 'Unenroll'}
      </Button>
    </Container>
  </ActionContainer>
);

CardAttendanceRenderUncancellableEnroll.propTypes = {
  unenrollLabel: PropTypes.string,
};

export const CardAttendanceRenderFullCapacity = ({ event: { event_type: eventType } }) => {
  const { toggle_request_event: toggleRequestEvent } = useToggles();

  return toggleRequestEvent && eventType ? (
    <SwitchAttendanceContainer>
      <Container>
        <Pill variant="lightGray" size="small" label="No spots left" />
      </Container>
      <RequestEventModal
        eventType={eventType}
        renderButton={({ handleModalOpen }) => (
          <Button size="small" color="secondary" onClick={handleModalOpen}>
            Request an event
          </Button>
        )}
      />
    </SwitchAttendanceContainer>
  ) : (
    <ActionContainer>
      <Container>
        <Pill variant="lightGray" size="small" label="No spots left" />
      </Container>
    </ActionContainer>
  );
};

CardAttendanceRenderFullCapacity.propTypes = {
  event: PropTypes.object,
};
