import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Container, Paper, IconButton, CardMedia, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useHistory } from 'react-router';

import PageTitle from 'app/shared/components/PageTitle/PageTitle';
import colors from 'services/colors';

import { Channel } from '../types';

type ChannelAddContentPageProps = {
  channel: Channel;
};

export function ChannelAddContentPage(props: ChannelAddContentPageProps) {
  const { channel } = props;

  const history = useHistory();

  const handleGoBack = () => {
    if (history.length > 1) {
      history.goBack();
    }
  };

  return (
    <Box position="relative">
      <PageTitle title="Add channel content" />
      <Box
        sx={{
          position: 'absolute',
          height: '346px',
          left: '0',
          right: '0',
          zIndex: '-1',
          backgroundColor: '#B586C8',
        }}
      />
      <Box pt={3}>
        <Container>
          <Paper
            square={false}
            sx={{
              borderRadius: 2,
              display: 'flex',
              padding: '8px 18px',
              minHeight: '66px',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <IconButton onClick={handleGoBack}>
              <ArrowBackIcon sx={{ color: colors.action700 }} fontSize="small" />
            </IconButton>
            <Box display="flex" gap={2} alignItems="center">
              {channel.cover && (
                <CardMedia
                  component="img"
                  sx={{
                    width: '100px',
                    height: '50px',
                    objectFit: 'contain',
                    borderStyle: 'solid',
                    borderColor: grey['400'],
                    borderRadius: 1,
                    borderWidth: '1px',
                  }}
                  alt={channel.name}
                  src={channel.cover}
                />
              )}
              <Box display="flex" flexDirection="column" flex={1}>
                <Typography variant="h6">{channel.name}</Typography>
                <Typography variant="body2">Channel</Typography>
              </Box>
            </Box>
          </Paper>
        </Container>
      </Box>
    </Box>
  );
}
