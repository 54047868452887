import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Paper, Divider, Stack, CardMedia, Typography, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

import RQLSearchBar from 'app/filters/components/inputs/RQLSearchBar/RQLSearchBar';
import { useNewRqlFilters } from 'app/filters/hooks';
import { RQLComp } from 'app/shared/components/types';
import ContentDescription from 'app/stand-alone-shared/components/ContentDescription/ContentDescription';
import colors from 'services/colors';
import { get, includes } from 'vendor/lodash';

import { useChannelRoutes } from '../../hooks/useChannelRoutes';
import { Channel } from '../../types';

import ChannelContextMenu from './ChannelContextMenu';
import { ChannelHeaderStats } from './ChannelHeaderStats';
import { ChannelOwnerPill } from './ChannelOwnerPill';

type ChannelDescriptionProps = {
  description: string;
};

function ChannelDescription(props: ChannelDescriptionProps) {
  const { description } = props;

  return (
    <Box
      sx={{
        fontSize: '14px',
        marginTop: '-0.5rem',
      }}
    >
      <ContentDescription description={description} noPadding />
    </Box>
  );
}

type ChannelHeaderProps = {
  channel: Channel;
};

export function ChannelHeader(props: ChannelHeaderProps) {
  const { channel } = props;

  const channelPermissions = get(channel, 'permissions', []);
  const canAddContent = includes(channelPermissions, 'add_content');
  const canManage = includes(channelPermissions, 'manage');

  const { addContent: addContentRoute } = useChannelRoutes({ withOriginRoute: true });

  const { filters, updateFilter } = useNewRqlFilters();

  const publicIdAndSlug = get(channel, 'public_id_and_slug');
  const description = get(channel, 'content_body');
  const owner = get(channel, 'owners[0]');

  return (
    <Paper square={false} sx={{ borderRadius: 2, display: 'flex', position: 'relative' }}>
      {canManage && (
        <Box position="absolute" right="17px" top="14px">
          <ChannelContextMenu channel={channel} />
        </Box>
      )}
      <Stack direction="column" divider={<Divider />} flexGrow={1} sx={{ flex: 1 }}>
        <Box p="20px" display="flex" gap={5} flex={1}>
          {channel.cover && (
            <CardMedia
              component="img"
              sx={{
                height: 200,
                width: 350,
                borderRadius: '4px',
                objectFit: 'contain',
              }}
              alt={channel.name}
              src={channel.cover}
            />
          )}
          <Box flex={1} display="flex">
            <Grid container flex={1}>
              <Grid item xs={6}>
                <Stack direction="column" spacing="17px">
                  <Typography
                    variant="h5"
                    component="h1"
                    color={colors.neutral900}
                    fontWeight="500"
                  >
                    {channel.name}
                  </Typography>
                  {description && (
                    <Box>
                      <ChannelDescription description={description} />
                    </Box>
                  )}
                  <ChannelHeaderStats channel={channel} />
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: 'flex', flexDirection: 'column-reverse', alignItems: 'flex-end' }}
              >
                {owner && <ChannelOwnerPill owner={owner} />}
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box p="20px" display="flex" gap="12px">
          <RQLSearchBar
            placeholder="Search Channel..."
            value={get(filters, 'q', null) as RQLComp | null}
            onChange={(newValue) => updateFilter({ q: newValue })}
            width="100%"
          />
          <Box>
            {canAddContent && (
              <Button
                variant="outlined"
                color="primary"
                sx={{ height: '40px' }}
                startIcon={<AddCircleOutlineIcon />}
                component={Link}
                to={addContentRoute({ public_id_and_slug: publicIdAndSlug })}
              >
                Add content
              </Button>
            )}
          </Box>
        </Box>
      </Stack>
    </Paper>
  );
}
