import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import { ApiURLs, fetchURL } from 'services/requests-base';
import { Button, Modal } from 'shared';
import Text from 'shared/components/Text';
import { isEmpty } from 'vendor/lodash';

const Content = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PreviewWarningNotice = styled.div`
  border-radius: 4px;
  background-color: ${colors.alert100};
  padding: 8px;
`;

const PreviewItemContent = styled.div`
  border-radius: 4px;
  background-color: ${colors.neutral100};
  padding: 8px;
`;

const useNotificationPreview = () => {
  const url = ApiURLs['api_notifications:preview']();

  const [preview, setPreview] = useState(null);

  const fetch = (template) => {
    setPreview(null);

    return fetchURL(url, {
      method: 'POST',
      body: JSON.stringify({ template }),
    })
      .then((response) => {
        const { data } = response;
        setPreview(data.preview);
        return response;
      })
  };

  return { preview, fetch };
};

const TemplatePreview = ({ template, settingLabel }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { preview, fetch } = useNotificationPreview();

  useEffect(() => {
    if (!preview) return;
    setIsOpen(true);
  }, [preview]);

  return (
    <div>
      <Button
        variant="text"
        onClick={() => {
          fetch(template);
        }}
        size="small"
        disabled={isEmpty(template)}
      >
        Preview
      </Button>
      {isOpen && (
        <Modal handleClose={() => setIsOpen(false)} title={`${settingLabel} - Preview`} width={600}>
          <Content>
            <div>
              <PreviewItemContent>
                <Text block dangerouslySetInnerHTML={{ __html: preview }} />
              </PreviewItemContent>
            </div>
            <PreviewWarningNotice>
              <Text block>Please note that the preview uses demonstration data</Text>
            </PreviewWarningNotice>
          </Content>
        </Modal>
      )}
    </div>
  );
};

TemplatePreview.propTypes = {
  settingLabel: PropTypes.string,
  template: PropTypes.string,
};

export default TemplatePreview;
