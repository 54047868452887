import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import RequestEventModal from 'event-types/components/RequestEventModal';
import Button from 'shared/components/Button';
import Pill from 'shared/components/Pill';
import { useToggles } from 'shared/hooks';

const Wrapper = styled.div`
  display: inherit;
  align-items: center;
  gap: 5px;

  > * + * {
    margin-left: 8px;
  }
`;

export const FullCapacityActionButtons = ({ event }) => {
  const { event_type: eventType } = event;
  const { toggle_request_event: toggleRequestEvent } = useToggles();

  return (
    <Wrapper>
      <Pill variant="lightGray" label="No spots left" />
      {toggleRequestEvent && eventType && (
        <RequestEventModal
          eventType={eventType}
          renderButton={({ handleModalOpen }) => (
            <Button onClick={handleModalOpen}>Request an event</Button>
          )}
        />
      )}
    </Wrapper>
  );
};

FullCapacityActionButtons.propTypes = {
  event: PropTypes.object,
};
