import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Field, Fields, reduxForm, formValueSelector, SubmissionError } from 'redux-form';
import styled from 'styled-components';

import AssessmentQuestionModal from 'assessments/components/AssessmentQuestionModal';
import { CONTENT_TYPES, FACILITATOR_ROLES } from 'catalog/constants';
import { parseResources } from 'content-resources/services';
import actions from 'entities/actions';
import { programSelector } from 'entities/selectors';
import CoverImageField from 'inputs/components/CoverImageField';
import DurationField, { validateDuration } from 'inputs/components/DurationField';
import FacilitatorField from 'inputs/components/FacilitatorField';
import InlineItemField from 'inputs/components/InlineItemField';
import InputLabel from 'inputs/components/InputLabel';
import TextEditorField, { TextEditorContainer } from 'inputs/components/OldTextEditorField';
import SectionTrackItemFields from 'inputs/components/SectionTrackItemFields';
import TagTypesInputFields from 'inputs/components/TagTypesInputFields';
import TextField from 'inputs/components/TextField';
import { SidebarButton } from 'navigation/components/FilterPage';
import { toast } from 'notifications/components/NotificationCenter';
import colors from 'services/colors';
import { useSettingsSectionsList } from 'settings/hooks';
import { prepareSettingsValuesForSubmission } from 'settings/services';
import Button from 'shared/components/Button';
import { FormFieldGroup } from 'shared/components/Form';
import NewContentForm from 'shared/components/NewContentForm';
import Form from 'shared/components/OldForm';
import Text from 'shared/components/Text';
import { useFormSelector, useLabels, useFormPreventTransition } from 'shared/hooks';
import DeleteStandAloneModal from 'stand-alone-shared/components/DeleteStandAloneModal';
import { buildCustomTopicsList, buildFlexibleTagsFieldNameList } from 'topics/services';
import TrackItemAddContentModal from 'tracks/components/TrackItemAddContentModal';
import TrackItemsForm from 'tracks/components/TrackItemsForm';
import {
  useCreateTrackSection,
  useAddTrackItems,
  useTrackForm,
  useUpdateTrackItemContent,
} from 'tracks/hooks';
import { getTrackSectionsAndItemsCountText } from 'tracks/services';
import {
  capitalize,
  concat,
  get,
  isEmpty,
  isNil,
  map,
  noop,
  omit,
  pick,
  size,
  toLower,
} from 'vendor/lodash';
import { AddIcon, MenuOpenOutlinedIcon, SplitscreenIcon } from 'vendor/mui-icons';
import { Box, IconButton, Menu, MenuItem } from 'vendor/mui.tsx';
import { onSubmitActions } from 'vendor/redux-form-submit-saga';

export const AssessmentQuestionModalsContext = createContext(noop);

const validateRequired = Form.validations.required();

const ACTION_BUTTON_SX = {
  color: colors.emphasis700,
  borderColor: colors.emphasis700,
  '&:hover': {
    color: colors.emphasis800,
    borderColor: colors.emphasis800,
  },
};

const CoverContainer = styled.div`
  display: flex;
`;

const addContentModalInitialValues = {
  type: '',
  section: null,
  trackIds: [],
  initialValues: {},
};

const TrackForm = ({
  form,
  topBarActionName,
  isEdit,
  backRoute,
  change,
  invalid,
  error,
  handleSubmit,
  submitting,
  isModalForm,
  initialValues,
  breadcrumbsItemList,
  selectedOfficeHour,
  currentUser,
  trackType,
}) => {
  const location = useLocation();

  useFormPreventTransition(form);

  const [addContentModalCtx, setAddContentModalCtx] = useState(addContentModalInitialValues);

  const [deleteModalContent, setDeleteModalContent] = useState(false);
  const [onDeleteContent, setOnDeleteContent] = useState();

  const showDeleteModal = (modalContent, onDelete) => {
    setOnDeleteContent(onDelete ? () => onDelete : undefined);
    setDeleteModalContent(modalContent);
  };

  const trackName = useFormSelector(form, 'name');
  const trackSections = useFormSelector(form, 'sections');
  const trackItems = useFormSelector(form, 'track_items');
  const {
    labelModel,
    toggleTypes,
    settingsName,
    HCArticleURL,
    infoPanelText,
    extraSections,
    advancedSettingsSections,
  } = useTrackForm(form, trackType, isEdit, selectedOfficeHour, change, initialValues);
  const {
    label_question: labelQuestion,
    label_question_plural: labelQuestionPlural,
    label_choice: labelChoice,
  } = useLabels();

  const handleDisplayAddContentModal = (type, options = {}) => {
    const { section = null, initialValues = {} } = options;

    let trackIds = options.trackIds || [];

    // Prevent adding duplicated track items
    if (type === 'trackItem') {
      const currentTrackItemIds = map(trackItems, 'content_item.id');
      trackIds = [...trackIds, ...currentTrackItemIds];
    }

    setAddContentModalCtx({
      type,
      section,
      trackIds,
      initialValues,
    });
  };

  const handleCloseContentModal = () => {
    setAddContentModalCtx(addContentModalInitialValues);
  };

  const [settingsSectionsList] = useSettingsSectionsList(settingsName, form);
  const advancedSettingsList = [...advancedSettingsSections, ...settingsSectionsList];

  const handleAppendToEditor = (url) => {
    change('content_body', { type: 'link_embed', content: url });
  };

  const handleAddTrackItems = useAddTrackItems(trackItems || [], change);
  const handleAddSection = useCreateTrackSection(trackSections || [], trackItems || [], change);
  const handleEditTrackItem = useUpdateTrackItemContent(trackItems || [], change);

  const lowerLabelModel = toLower(labelModel);

  const trackSectionsList = [
    ...(trackType !== CONTENT_TYPES.scheduled_track
      ? [
          {
            id: 'inline',
            label: 'Inline Item',
            icon: 'inline',
            section: <InlineItemField />,
            sectionProps: {
              defaultOpen: true,
            },
          },
        ]
      : []),
    {
      id: 'track-details',
      label: `${labelModel} Details`,
      icon: 'info',
      section: (
        <>
          <FormFieldGroup>
            <Field
              name="name"
              label="Title"
              required
              component={TextField}
              placeholder={`Give your ${lowerLabelModel} a title`}
              validate={[validateRequired]}
            />
          </FormFieldGroup>
          <FormFieldGroup>
            <CoverContainer>
              <Field
                name="cover"
                component={CoverImageField}
                imageWidth="412px"
                imageHeight="231px"
                filePath="track_covers"
              />
            </CoverContainer>
          </FormFieldGroup>
          <FormFieldGroup>
            <InputLabel htmlFor="content_body" required>
              Description
            </InputLabel>
            <TextEditorContainer>
              <Field
                name="content_body"
                component={TextEditorField}
                allowGenericLinks
                allowImageButton
                allowVideoButton
                validate={[validateRequired]}
                handleAppendToEditor={handleAppendToEditor}
              />
            </TextEditorContainer>
          </FormFieldGroup>
          <Fields
            useNewFormFieldLabel
            names={['topics', 'tags', ...buildFlexibleTagsFieldNameList(currentUser, toggleTypes)]}
            component={TagTypesInputFields}
            currentUser={currentUser}
            toggleTypes={toggleTypes}
          />
          {trackType === CONTENT_TYPES.assessment && (
            <FormFieldGroup>
              <Field
                inputId="duration"
                name="duration"
                label="Estimated Duration (H:MM)"
                component={DurationField}
                validate={[validateDuration]}
                disableClearable={false}
                aditionalOptions={[{ label: '0:10', value: '0:10' }]}
              />
            </FormFieldGroup>
          )}
        </>
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'facilitators',
      label: 'Facilitators',
      icon: 'persons',
      section: (
        <FacilitatorField
          label="Maintainer(s)"
          name="maintainers_ids"
          infoText={`Responsible for maintaining the content, able to delete the ${lowerLabelModel}
          and change access restrictions. Whenever the ${lowerLabelModel} maintainers are updated,
          the new ones are automatically added as maintainers to the inline items while the
          removed ones are not removed.`}
        />
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    ...extraSections,
  ];

  const trackSectionsAndItemsCountText = getTrackSectionsAndItemsCountText(
    trackSections,
    trackItems
  );

  const trackItemsLabel = trackType === CONTENT_TYPES.assessment ? labelQuestionPlural : 'Items';

  const [questionMenuAnchor, setQuestionMenuAnchor] = useState(null);

  return (
    <AssessmentQuestionModalsContext.Provider
      value={{ showContentModal: handleDisplayAddContentModal, showDeleteModal }}
    >
      <NewContentForm
        contentNameSingular={labelModel}
        contentInfoPanelText={infoPanelText}
        contentHCArticleURL={HCArticleURL}
        invalid={invalid}
        handleSubmit={handleSubmit}
        error={error}
        change={change}
        submitting={submitting}
        contentTitle={trackName}
        topBarActionName={topBarActionName}
        isEdit={isEdit}
        backRoute={backRoute}
        breadcrumbsItemList={breadcrumbsItemList}
        contentSectionsList={trackSectionsList}
        advancedSettingsList={advancedSettingsList}
        isModalForm={isModalForm}
        sidebarComponentContent={({ openSidebar }) => (
          <SidebarButton onClick={openSidebar} renderContent={() => <MenuOpenOutlinedIcon />} />
        )}
        // Show details tab if there is a hash in the URL
        // Show 'track-items' tab  by default if there's track items or sections
        defaultSelectedTab={
          isEmpty(location?.hash) && (!isEmpty(trackItems) || !isEmpty(trackSections))
            ? 'track-items'
            : undefined
        }
        tabsConfig={[
          {
            id: 'track-items',
            dataTestid: 'track-items',
            label: `${capitalize(labelModel)} ${trackItemsLabel}`,
            renderSidebar: ({ closeSidebar }) => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '12px',
                  justifyContent: 'center',
                  p: '16px',
                }}
              >
                <Box display="flex" justifyContent="flex-start" alignItems="center" gap="8px">
                  <IconButton size="small" onClick={closeSidebar}>
                    <MenuOpenOutlinedIcon />
                  </IconButton>
                  <Text size="h6" color={colors.neutral500}>
                    {trackSectionsAndItemsCountText}
                  </Text>
                </Box>

                {isEmpty(trackItems) && isEmpty(trackSections) && (
                  <>
                    <Button
                      type="button"
                      startIcon={<AddIcon />}
                      sx={ACTION_BUTTON_SX}
                      onClick={(event) => {
                        if (trackType === CONTENT_TYPES.assessment) {
                          return setQuestionMenuAnchor(event?.currentTarget);
                        }

                        handleDisplayAddContentModal('trackItem');
                      }}
                    >
                      Add{' '}
                      {trackType === CONTENT_TYPES.assessment ? toLower(labelQuestion) : 'content'}
                    </Button>
                    <Button
                      startIcon={<SplitscreenIcon />}
                      sx={ACTION_BUTTON_SX}
                      onClick={handleAddSection}
                    >
                      Add section
                    </Button>
                  </>
                )}

                <Fields
                  names={['sections', 'track_items']}
                  component={SectionTrackItemFields}
                  isEditing={isEdit}
                  trackType={trackType}
                />

                <Menu
                  anchorEl={questionMenuAnchor}
                  open={!!questionMenuAnchor}
                  onClose={() => setQuestionMenuAnchor(null)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleDisplayAddContentModal(CONTENT_TYPES.multiple_choice_question);
                      setQuestionMenuAnchor(null);
                    }}
                  >
                    Multiple {capitalize(labelChoice)}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDisplayAddContentModal(CONTENT_TYPES.text_question);
                      setQuestionMenuAnchor(null);
                    }}
                  >
                    Short Answer
                  </MenuItem>
                </Menu>
              </Box>
            ),
            renderContent: ({ error, invalid, submitting }) => (
              <TrackItemsForm
                sections={trackSections || []}
                items={trackItems || []}
                {...{ error, invalid, submitting, change, trackType, isEdit }}
              />
            ),
          },
        ]}
      />
      {addContentModalCtx.type === 'trackItem' && (
        <TrackItemAddContentModal
          handleClose={handleCloseContentModal}
          callback={({ selectedItems: newItems }) => {
            handleAddTrackItems(addContentModalCtx.section ?? {}, newItems);
            handleCloseContentModal();
          }}
          trackType={trackType}
          trackItemsIds={addContentModalCtx.trackIds}
          trackFormName={form}
          trackId={initialValues?.id}
        />
      )}
      {(addContentModalCtx.type === CONTENT_TYPES.text_question ||
        addContentModalCtx.type === CONTENT_TYPES.multiple_choice_question) && (
        <AssessmentQuestionModal
          handleSubmit={(payload, isEdit = false) => {
            if (!isEdit) {
              handleAddTrackItems(addContentModalCtx.section ?? {}, [payload]);
            } else {
              handleEditTrackItem(payload);
              toast.success(
                `${labelQuestion} updated successfully!`,
                'This change is already published.'
              );
            }
            handleCloseContentModal();
          }}
          handleClose={handleCloseContentModal}
          questionType={addContentModalCtx.type}
          initialValuesParams={addContentModalCtx.initialValues}
        />
      )}
      {deleteModalContent && (
        <DeleteStandAloneModal
          content={deleteModalContent}
          handleClose={() => showDeleteModal(false)}
          onDelete={onDeleteContent}
        />
      )}
    </AssessmentQuestionModalsContext.Provider>
  );
};

TrackForm.defaultProps = {
  initialValues: {},
  trackType: CONTENT_TYPES.track,
};

TrackForm.propTypes = {
  form: PropTypes.string,

  // Redux Form props
  invalid: PropTypes.bool,
  error: PropTypes.object,
  submitting: PropTypes.bool,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,

  initialValues: PropTypes.object,
  isEdit: PropTypes.bool,
  isModalForm: PropTypes.bool,
  trackType: PropTypes.oneOf([
    CONTENT_TYPES.track,
    CONTENT_TYPES.scheduled_track,
    CONTENT_TYPES.assessment,
  ]),

  breadcrumbsItemList: PropTypes.arrayOf(PropTypes.object),

  topBarActionName: PropTypes.string,
  backRoute: PropTypes.string,

  selectedOfficeHour: PropTypes.object,
  currentUser: PropTypes.object,
};

const ConnectedTrackForm = reduxForm({
  // Not passing 'form' here, pass it as a prop to the component
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  onSubmit: (values, dispatch, { formName, currentUser, initialValues, isEdit, trackType }) => {
    const isAssessment = trackType === CONTENT_TYPES.assessment;
    const formActions = isAssessment ? actions.assessment : actions.track;

    const actionName = formActions[isEdit ? 'update' : 'create'].toString();

    return onSubmitActions(actionName, () => {
      const payload = pick(values, [
        'content_body',
        'cover',
        'duration',
        'external_survey_link',
        'feedback_policy',
        'groups_ids',
        'hide_attendees',
        'is_hidden',
        'is_inline',
        'maintainers_ids',
        'max_attempts',
        'name',
        'office_hour_id',
        'passing_score',
        'resources',
        'sections',
        'survey_relationships',
        'time_to_complete',
        'time_to_retake',
        'topics',
        'track_items',
      ]);

      const toggleTypes = isAssessment ? ['toggle_assessments'] : ['toggle_tracks'];
      const customTopicsList = buildCustomTopicsList({
        user: currentUser,
        toggleTypes,
        flexibleTags: pick(
          values,
          map(
            currentUser?.custom_tags.flexible_filter_tags,
            (flexibleTag) => flexibleTag.filter_field_name
          )
        ),
        tags: values.tags,
      });

      const topics = get(payload, 'topics') && !isNil(payload.topics[0]) ? payload.topics : [];

      payload.tags = concat(topics, customTopicsList);

      payload.sections = map(get(payload, 'sections', []), (section) => omit(section, ['items']));

      const track_items = map(get(payload, 'track_items', []), (item) => ({
        content_item_id: get(item, 'content_item.id'),
        section: get(item, 'section'),
        is_required: get(item, 'is_required', true),
        content_item_is_inline: get(item, 'content_item.is_inline'),
      }));

      payload.track_items = track_items;

      if (size(payload.track_items) === 0 && !isAssessment) {
        const labelTrack = currentUser?.labels?.label_track;
        const submissionErrorMessage = `The ${labelTrack} should contain at least one item. Please add some content before attempting to save.`;
        throw new SubmissionError({
          track_items: submissionErrorMessage,
          _error: submissionErrorMessage,
        });
      }

      payload.facilitators = map(get(payload, 'maintainers_ids', []), (maintainerId) => ({
        user_id: maintainerId,
        role: isAssessment ? FACILITATOR_ROLES.maintainer : FACILITATOR_ROLES.author,
      }));

      // Surveys
      let { survey_relationships: surveyRelationships } = payload;
      const { external_survey_link: surveyLink } = payload;

      surveyRelationships = map(surveyRelationships, (sr) => {
        const surveyRelationship = pick(sr, [
          'survey_id',
          'survey_cutoff_period_value',
          'survey_cutoff_period_option',
          'survey_schedule_period_value',
          'survey_schedule_period_option',
          'survey_schedule_kind',
        ]);

        if (isEdit) surveyRelationship.id = sr.id;

        return surveyRelationship;
      });

      payload.external_survey_link = surveyLink;
      payload.survey_relationships = surveyRelationships;

      // Resources Links
      payload.resources = parseResources(payload.resources, isEdit);

      // Settings
      payload.settings = prepareSettingsValuesForSubmission(values?.settings);

      if (isAssessment) {
        if (!values.passing_score_enabled) {
          payload.passing_score = 0;
        } else {
          payload.passing_score /= 100;
        }
      }

      const params = initialValues ? { id: initialValues.id } : {};

      return {
        ...params,
        key: formName,
        body: payload,
      };
    })(values, dispatch);
  },
  onSubmitSuccess: (result, dispatch, { onSubmitSuccessHandler }) => onSubmitSuccessHandler(result),
  onSubmitFail: () => {
    window.scrollTo(0, 0);
  },
})(TrackForm);

const mapStateToProps = (state, { form }) => {
  const formSelector = formValueSelector(form);
  const selectedOfficeHour = programSelector(state, formSelector(state, 'office_hour_id'), null);

  return {
    selectedOfficeHour,
    currentUser: get(state, 'user.currentUser'),
  };
};

export default connect(mapStateToProps)(ConnectedTrackForm);
