import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { LEARNING_TYPE_ICONS_MAPPING, LEARNING_TYPES, CHANNEL } from 'catalog/constants';
import { useLearningTypeLabels } from 'catalog/hooks';
import { useChannelRoutes } from 'features/channels/hooks/useChannelRoutes';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import Icon from 'shared/components/Icon';
import {
  useCurrentUser,
  useEncodedCurrentRoute,
  useModuleToggles,
  useToggles,
  useQueryParams,
  useLabels,
  useHasChannelPermission,
} from 'shared/hooks';
import * as permissionConstants from 'shared/permissions';
import { includes, isString } from 'vendor/lodash';
import { FactCheckIcon, LinkIcon, AddIcon } from 'vendor/mui-icons';

function LearningMenuItem(props) {
  const { title, icon: IconProp, route, color, ...restButtonProps } = props;

  return (
    <MenuItem {...restButtonProps} component={route ? Link : undefined} to={route ?? undefined}>
      {IconProp ? (
        <ListItemIcon>
          {isString(IconProp) ? (
            <Icon name={IconProp} width={18} height={18} color={color} />
          ) : (
            <IconProp
              sx={{
                width: 18,
                height: 18,
                color,
              }}
            />
          )}
        </ListItemIcon>
      ) : null}
      <ListItemText
        sx={{
          color: colors.neutral600,
        }}
      >
        {title}
      </ListItemText>
    </MenuItem>
  );
}

LearningMenuItem.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  color: PropTypes.string,
  route: PropTypes.string,
};

function useCreateLearningPermissions() {
  const {
    permissions,
    scorm_cloud_integration_toggle: toggleCourses,
    catalog_sections_order: catalogSectionsOrder,
  } = useCurrentUser();

  const { programs: togglePrograms, tracks: toggleSnippets } = useModuleToggles();
  const { toggle_mentorship_programs: toggleMentorshipPrograms } = useToggles();
  const { fixed, learning_types: selectedLearningTypes } = useQueryParams();

  const isNotInFixedViewMode = !fixed;

  const canCreateEventType =
    includes(permissions, permissionConstants.CREATE_EVENT_TYPE_PERMISSION) &&
    (isNotInFixedViewMode || includes(selectedLearningTypes, LEARNING_TYPES.event_types));
  const canCreateProgram =
    (togglePrograms || includes(catalogSectionsOrder, LEARNING_TYPES.programs)) &&
    includes(permissions, permissionConstants.CREATE_PROGRAM_PERMISSION) &&
    (isNotInFixedViewMode || includes(selectedLearningTypes, LEARNING_TYPES.programs));
  const canCreateMentorshipProgram =
    toggleMentorshipPrograms &&
    includes(permissions, permissionConstants.CREATE_PROGRAM_PERMISSION) &&
    (isNotInFixedViewMode || includes(selectedLearningTypes, LEARNING_TYPES.mentorship_programs));
  const canCreateTrack =
    (toggleSnippets || includes(catalogSectionsOrder, LEARNING_TYPES.tracks)) &&
    includes(permissions, permissionConstants.CREATE_TRACK_PERMISSION) &&
    (isNotInFixedViewMode || includes(selectedLearningTypes, LEARNING_TYPES.tracks));
  const canCreateArticle =
    (toggleSnippets || includes(catalogSectionsOrder, LEARNING_TYPES.articles)) &&
    includes(permissions, permissionConstants.CREATE_ARTICLE_PERMISSION) &&
    (isNotInFixedViewMode || includes(selectedLearningTypes, LEARNING_TYPES.articles));
  const canCreateCodelab =
    includes(catalogSectionsOrder, LEARNING_TYPES.codelabs) &&
    includes(permissions, permissionConstants.CREATE_CODELAB_PERMISSION) &&
    (isNotInFixedViewMode || includes(selectedLearningTypes, LEARNING_TYPES.codelabs));
  const canCreateCourses =
    toggleCourses &&
    includes(catalogSectionsOrder, LEARNING_TYPES.courses) &&
    includes(permissions, permissionConstants.CREATE_COURSE_PERMISSION) &&
    (isNotInFixedViewMode || includes(selectedLearningTypes, LEARNING_TYPES.courses));
  const canCreateVideo =
    (toggleSnippets || includes(catalogSectionsOrder, LEARNING_TYPES.videos)) &&
    includes(permissions, permissionConstants.CREATE_VIDEO_PERMISSION) &&
    (isNotInFixedViewMode || includes(selectedLearningTypes, LEARNING_TYPES.videos));
  const canCreateAssessment =
    includes(catalogSectionsOrder, LEARNING_TYPES.assessments) &&
    includes(permissions, permissionConstants.CREATE_ASSESSMENT_PERMISSION) &&
    (isNotInFixedViewMode || includes(selectedLearningTypes, LEARNING_TYPES.assessments));
  const canCreateLinkedContent =
    includes(catalogSectionsOrder, LEARNING_TYPES.linkedcontent) &&
    includes(permissions, permissionConstants.CREATE_LINKEDCONTENT_PERMISSION) &&
    (isNotInFixedViewMode || includes(selectedLearningTypes, LEARNING_TYPES.linkedcontent));
  const canCreateChannel = useHasChannelPermission(permissionConstants.MANAGE_CHANNEL_PERMISSION);

  const canCreateAnyLearningType =
    canCreateEventType ||
    canCreateProgram ||
    canCreateMentorshipProgram ||
    canCreateTrack ||
    canCreateArticle ||
    canCreateCodelab ||
    canCreateCourses ||
    canCreateVideo ||
    canCreateAssessment ||
    canCreateLinkedContent ||
    canCreateChannel;

  const allPermissions = {
    canCreateEventType,
    canCreateProgram,
    canCreateMentorshipProgram,
    canCreateTrack,
    canCreateArticle,
    canCreateCodelab,
    canCreateCourses,
    canCreateVideo,
    canCreateAssessment,
    canCreateLinkedContent,
    canCreateChannel,
  };

  return [canCreateAnyLearningType, allPermissions];
}

const AllLearningsButton = (props) => {
  const { onCodelabClickCallback, onCourseClickCallback, onLinkedContentClickCallback } = props;

  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const currentRoute = useEncodedCurrentRoute();
  const { label_channel: labelChannel } = useLabels();
  const { create: channelCreateRoute } = useChannelRoutes({ withOriginRoute: true });

  const learningTypeLabels = useLearningTypeLabels();

  const [canCreateAnyLearningType, permissions] = useCreateLearningPermissions();

  const handleOpenMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const wrapMenuClose = (callback) => {
    return (event) => {
      handleCloseMenu();
      callback(event);
    };
  };

  if (!canCreateAnyLearningType) {
    return null;
  }

  return (
    <>
      <Button
        variant="contained"
        aria-label="New Content Item"
        sx={{
          height: 40,
        }}
        aria-controls={isMenuOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        onClick={handleOpenMenu}
        startIcon={<AddIcon />}
      >
        New
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {permissions.canCreateEventType && (
          <LearningMenuItem
            title={learningTypeLabels[LEARNING_TYPES.event_types]}
            route={`${mapRoute('eventTypeCreate')}?origin=${currentRoute}`}
            icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.event_types]}
            color={colors.learningEventType700}
            aria-label="New Event Type"
          />
        )}
        {permissions.canCreateProgram && (
          <LearningMenuItem
            title={learningTypeLabels[LEARNING_TYPES.programs]}
            route={`${mapRoute('programCreate')}?origin=${currentRoute}`}
            icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.programs]}
            color={colors.learningProgram700}
            aria-label="New Program"
          />
        )}
        {permissions.canCreateMentorshipProgram && (
          <LearningMenuItem
            title={learningTypeLabels[LEARNING_TYPES.mentorship_programs]}
            route={`${mapRoute('mentorshipProgramCreate')}?origin=${currentRoute}`}
            icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.mentorship_programs]}
            color={colors.learningMentorshipProgram700}
            aria-label="New Program"
          />
        )}
        {permissions.canCreateTrack && (
          <LearningMenuItem
            title={learningTypeLabels[LEARNING_TYPES.tracks]}
            route={`${mapRoute('trackCreate')}?origin=${currentRoute}`}
            icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.tracks]}
            color={colors.learningTrack700}
            aria-label="New Track"
          />
        )}
        {permissions.canCreateArticle && (
          <LearningMenuItem
            title={learningTypeLabels[LEARNING_TYPES.articles]}
            route={`${mapRoute('articleCreate')}?origin=${currentRoute}`}
            icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.articles]}
            color={colors.learningArticle700}
            aria-label="New Article"
          />
        )}
        {permissions.canCreateCodelab && (
          <LearningMenuItem
            title={learningTypeLabels[LEARNING_TYPES.codelabs]}
            onClick={wrapMenuClose(onCodelabClickCallback)}
            icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.codelabs]}
            color={colors.learningCodelab700}
            aria-label="New Codelab"
          />
        )}
        {permissions.canCreateCourses && (
          <LearningMenuItem
            title={learningTypeLabels[LEARNING_TYPES.courses]}
            onClick={wrapMenuClose(onCourseClickCallback)}
            icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.courses]}
            color={colors.learningCourse700}
            aria-label="New Course"
          />
        )}
        {permissions.canCreateVideo && (
          <LearningMenuItem
            title={learningTypeLabels[LEARNING_TYPES.videos]}
            route={`${mapRoute('videoCreate')}?origin=${currentRoute}`}
            icon={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.videos]}
            color={colors.learningVideo600}
            aria-label="New Video"
          />
        )}
        {permissions.canCreateAssessment && (
          <LearningMenuItem
            title={learningTypeLabels[LEARNING_TYPES.assessments]}
            route={`${mapRoute('assessmentCreate')}?origin=${currentRoute}`}
            icon={FactCheckIcon}
            color={colors.learningAssessment700}
            aria-label="New Assessment"
          />
        )}
        {permissions.canCreateLinkedContent && (
          <LearningMenuItem
            title={learningTypeLabels[LEARNING_TYPES.linkedcontent]}
            onClick={wrapMenuClose(onLinkedContentClickCallback)}
            icon={LinkIcon}
            color={colors.learningLinkedContent700}
            aria-label="New Link"
          />
        )}
        {permissions.canCreateChannel && (
          <LearningMenuItem
            title={labelChannel}
            route={channelCreateRoute}
            icon={CHANNEL.icon}
            color={CHANNEL.color}
            aria-label="New Channel"
          />
        )}
      </Menu>
    </>
  );
};

AllLearningsButton.propTypes = {
  onCodelabClickCallback: PropTypes.func,
  onCourseClickCallback: PropTypes.func,
  onLinkedContentClickCallback: PropTypes.func,
};

function SingleLearningBtn(props) {
  return (
    <Button
      startIcon={<Icon name="plus" />}
      variant="contained"
      sx={{
        height: 40,
      }}
      {...props}
    />
  );
}

const SingleLearningButton = ({
  learningType,
  onCodelabClickCallback,
  onCourseClickCallback,
  onLinkedContentClickCallback,
}) => {
  const currentRoute = useEncodedCurrentRoute();
  const {
    permissions,
    scorm_cloud_integration_toggle: toggleCourses,
    catalog_sections_order: catalogSectionsOrder,
  } = useCurrentUser();
  const { programs: togglePrograms, tracks: toggleSnippets } = useModuleToggles();

  const learningTypeLabels = useLearningTypeLabels();

  const canCreateEventType = includes(
    permissions,
    permissionConstants.CREATE_EVENT_TYPE_PERMISSION
  );
  const canCreateProgram =
    (togglePrograms || includes(catalogSectionsOrder, LEARNING_TYPES.programs)) &&
    includes(permissions, permissionConstants.CREATE_PROGRAM_PERMISSION);
  const canCreateTrack =
    (toggleSnippets || includes(catalogSectionsOrder, LEARNING_TYPES.tracks)) &&
    includes(permissions, permissionConstants.CREATE_TRACK_PERMISSION);
  const canCreateArticle =
    (toggleSnippets || includes(catalogSectionsOrder, LEARNING_TYPES.articles)) &&
    includes(permissions, permissionConstants.CREATE_ARTICLE_PERMISSION);
  const canCreateCodelab =
    (toggleSnippets || includes(catalogSectionsOrder, LEARNING_TYPES.codelabs)) &&
    includes(permissions, permissionConstants.CREATE_CODELAB_PERMISSION);
  const canCreateCourses =
    toggleCourses &&
    includes(catalogSectionsOrder, LEARNING_TYPES.courses) &&
    includes(permissions, permissionConstants.CREATE_COURSE_PERMISSION);
  const canCreateVideo =
    (toggleSnippets || includes(catalogSectionsOrder, LEARNING_TYPES.videos)) &&
    includes(permissions, permissionConstants.CREATE_VIDEO_PERMISSION);
  const canCreateAssessment =
    includes(catalogSectionsOrder, LEARNING_TYPES.assessments) &&
    includes(permissions, permissionConstants.CREATE_ASSESSMENT_PERMISSION);
  const canCreateLinkedContent =
    includes(catalogSectionsOrder, LEARNING_TYPES.linkedcontent) &&
    includes(permissions, permissionConstants.CREATE_LINKEDCONTENT_PERMISSION);

  if (learningType === LEARNING_TYPES.event_types && canCreateEventType) {
    return (
      <SingleLearningBtn
        route={`${mapRoute('eventTypeCreate')}?origin=${currentRoute}`}
        aria-label="New Event Type"
      >
        New {learningTypeLabels[LEARNING_TYPES.event_types]}
      </SingleLearningBtn>
    );
  }

  if (learningType === LEARNING_TYPES.programs && canCreateProgram) {
    return (
      <SingleLearningBtn
        route={`${mapRoute('programCreate')}?origin=${currentRoute}`}
        aria-label="New Program"
      >
        New {learningTypeLabels[LEARNING_TYPES.programs]}
      </SingleLearningBtn>
    );
  }
  if (learningType === LEARNING_TYPES.tracks && canCreateTrack) {
    return (
      <SingleLearningBtn
        route={`${mapRoute('trackCreate')}?origin=${currentRoute}`}
        aria-label="New Track"
      >
        New {learningTypeLabels[LEARNING_TYPES.tracks]}
      </SingleLearningBtn>
    );
  }
  if (learningType === LEARNING_TYPES.articles && canCreateArticle) {
    return (
      <SingleLearningBtn
        route={`${mapRoute('articleCreate')}?origin=${currentRoute}`}
        aria-label="New Article"
      >
        New {learningTypeLabels[LEARNING_TYPES.articles]}
      </SingleLearningBtn>
    );
  }
  if (learningType === LEARNING_TYPES.codelabs && canCreateCodelab) {
    return (
      <SingleLearningBtn onClick={onCodelabClickCallback} aria-label="New Codelab">
        New {learningTypeLabels[LEARNING_TYPES.codelabs]}
      </SingleLearningBtn>
    );
  }
  if (learningType === LEARNING_TYPES.courses && canCreateCourses) {
    return (
      <SingleLearningBtn onClick={onCourseClickCallback} aria-label="New Course">
        New Course
      </SingleLearningBtn>
    );
  }

  if (learningType === LEARNING_TYPES.videos && canCreateVideo) {
    return (
      <SingleLearningBtn
        route={`${mapRoute('videoCreate')}?origin=${currentRoute}`}
        aria-label="New Video"
      >
        New Video
      </SingleLearningBtn>
    );
  }
  if (learningType === LEARNING_TYPES.assessments && canCreateAssessment) {
    return (
      <SingleLearningBtn
        route={`${mapRoute('assessmentCreate')}?origin=${currentRoute}`}
        aria-label="New Assessment"
      >
        New {learningTypeLabels[LEARNING_TYPES.assessments]}
      </SingleLearningBtn>
    );
  }
  if (learningType === LEARNING_TYPES.linkedcontent && canCreateLinkedContent) {
    return (
      <SingleLearningBtn onClick={onLinkedContentClickCallback} aria-label="New Link">
        New {learningTypeLabels[LEARNING_TYPES.linkedcontent]}
      </SingleLearningBtn>
    );
  }

  return null;
};

SingleLearningButton.propTypes = {
  learningType: PropTypes.string,
  onCodelabClickCallback: PropTypes.func,
  onCourseClickCallback: PropTypes.func,
  onLinkedContentClickCallback: PropTypes.func,
};

const NewLearningButton = ({
  learningType,
  onCodelabClickCallback,
  onCourseClickCallback,
  onLinkedContentClickCallback,
}) => {
  if (learningType) {
    return (
      <SingleLearningButton
        learningType={learningType}
        onCodelabClickCallback={onCodelabClickCallback}
        onCourseClickCallback={onCourseClickCallback}
        onLinkedContentClickCallback={onLinkedContentClickCallback}
      />
    );
  }

  return (
    <AllLearningsButton
      onCodelabClickCallback={onCodelabClickCallback}
      onCourseClickCallback={onCourseClickCallback}
      onLinkedContentClickCallback={onLinkedContentClickCallback}
    />
  );
};

NewLearningButton.propTypes = {
  learningType: PropTypes.string,
  onCodelabClickCallback: PropTypes.func,
  onCourseClickCallback: PropTypes.func,
  onLinkedContentClickCallback: PropTypes.func,
};
export default NewLearningButton;
