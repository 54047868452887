import colors from 'services/colors';
import { useEllipsisCheck } from 'shared/hooks';
import { Box, Tooltip as MUITooltip, styled, TooltipProps, tooltipClasses } from 'vendor/mui';
import { LocationOnIcon } from 'vendor/mui-icons';

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MUITooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 280,
  },
});

interface Props {
  name: string;
}

const CardLocation = ({ name }: Props) => {
  const { hasEllipsis, nodeRef } = useEllipsisCheck();
  return (
    <Tooltip title={name} placement="top" disableHoverListener={!hasEllipsis} arrow>
      <Box
        ref={nodeRef}
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: colors.neutral600,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <LocationOnIcon sx={{ width: 16, height: 16 }} /> {name}
      </Box>
    </Tooltip>
  );
};

export default CardLocation;
