import {
  CalendarTodayIcon,
  CancelIcon,
  CheckCircleOutlineIcon,
  HighlightOffOutlinedIcon,
  LinearScaleOutlinedIcon,
  PersonRemoveAlt1Icon,
  RuleIcon,
  UnExemptionOutlineIcon,
  UnpublishedOutlinedIcon,
} from 'vendor/mui-icons';

export enum ASSIGNMENT_STATES {
  completed = 'completed',
  dropped = 'dropped',
  exempted = 'exempted',
  in_progress = 'in_progress',
  not_started = 'not_started',
}

export const ASSIGNMENT_STATES_LABELS = {
  [ASSIGNMENT_STATES.completed]: 'Completed',
  [ASSIGNMENT_STATES.dropped]: 'Dropped',
  [ASSIGNMENT_STATES.exempted]: 'Exempted',
  [ASSIGNMENT_STATES.in_progress]: 'In progress',
  [ASSIGNMENT_STATES.not_started]: 'Not started',
};

export enum ASSIGNMENT_PERMISSIONS {
  access = 'access',
  manage = 'manage',
  drop = 'drop',
  progress = 'progress',
  undo_progress = 'undo_progress',
  complete = 'complete',
  undo_complete = 'undo_complete',
  exempt = 'exempt',
  undo_exempt = 'undo_exempt',
  rate = 'rate',
  acknowledge_completion = 'acknowledge_completion',
}

export const ASSIGNMENT_STATES_ICONS = {
  [ASSIGNMENT_STATES.completed]: CheckCircleOutlineIcon,
  [ASSIGNMENT_STATES.dropped]: HighlightOffOutlinedIcon,
  [ASSIGNMENT_STATES.exempted]: UnpublishedOutlinedIcon,
  [ASSIGNMENT_STATES.in_progress]: LinearScaleOutlinedIcon,
};

export enum ASSIGNMENT_ACTIONS {
  update = 'update',
  exempt = 'exempt',
  undo_exempt = 'undo_exempt',
  complete = 'complete',
  undo_complete = 'undo_complete',
  drop = 'drop',
  view_progress = 'view_progress',
}

export const ASSIGNMENT_ACTIONS_LABELS = {
  [ASSIGNMENT_ACTIONS.update]: 'Edit',
  [ASSIGNMENT_ACTIONS.exempt]: 'Mark as Exempted',
  [ASSIGNMENT_ACTIONS.undo_exempt]: 'Unmark as Exempted',
  [ASSIGNMENT_ACTIONS.complete]: 'Mark as Completed',
  [ASSIGNMENT_ACTIONS.undo_complete]: 'Unmark as Completed',
  [ASSIGNMENT_ACTIONS.drop]: 'Drop',
  [ASSIGNMENT_ACTIONS.view_progress]: 'View Progress',
};

export const ASSIGNMENT_ACTIONS_ICONS = {
  [ASSIGNMENT_ACTIONS.update]: CalendarTodayIcon,
  [ASSIGNMENT_ACTIONS.exempt]: ASSIGNMENT_STATES_ICONS[ASSIGNMENT_STATES.exempted],
  [ASSIGNMENT_ACTIONS.undo_exempt]: UnExemptionOutlineIcon,
  [ASSIGNMENT_ACTIONS.complete]: ASSIGNMENT_STATES_ICONS[ASSIGNMENT_STATES.completed],
  [ASSIGNMENT_ACTIONS.undo_complete]: CancelIcon,
  [ASSIGNMENT_ACTIONS.drop]: PersonRemoveAlt1Icon,
  [ASSIGNMENT_ACTIONS.view_progress]: RuleIcon,
};

export enum ASSIGNMENT_REASONS {
  roster_bulk_assignment = 'roster_bulk_assignment',
  roster_bulk_assignment_update = 'roster_bulk_assignment_update',
  roster_bulk_assignment_complete = 'roster_bulk_assignment_complete',
  roster_bulk_assignment_undo_complete = 'roster_bulk_assignment_undo_complete',
  roster_bulk_assignment_exempt = 'roster_bulk_assignment_exempt',
  roster_bulk_assignment_undo_exempt = 'roster_bulk_assignment_undo_exempt',
  roster_bulk_assignment_drop = 'roster_bulk_drop',
  assignment_retry = 'assignment_retry',
}

export const ASSIGNMENT_ROSTER_ACTIONS_REASONS = {
  [ASSIGNMENT_ACTIONS.update]: ASSIGNMENT_REASONS.roster_bulk_assignment_update,
  [ASSIGNMENT_ACTIONS.complete]: ASSIGNMENT_REASONS.roster_bulk_assignment_complete,
  [ASSIGNMENT_ACTIONS.undo_complete]: ASSIGNMENT_REASONS.roster_bulk_assignment_undo_complete,
  [ASSIGNMENT_ACTIONS.exempt]: ASSIGNMENT_REASONS.roster_bulk_assignment_complete,
  [ASSIGNMENT_ACTIONS.undo_exempt]: ASSIGNMENT_REASONS.roster_bulk_assignment_undo_complete,
  [ASSIGNMENT_ACTIONS.drop]: ASSIGNMENT_REASONS.roster_bulk_assignment_complete,
};

export enum BULK_ASYNC_JOB_MESSAGES {
  not_allowed = 'not_allowed',
  skipped = 'skipped',
  no_spots_available = 'no_spots_available',
  waitlisted = 'waitlisted',
  enrolled = 'enrolled',
  exception_raised = 'exception_raised',
}

export const BULK_ASYNC_JOB_MESSAGES_LABELS = {
  [BULK_ASYNC_JOB_MESSAGES.not_allowed]: 'Not allowed',
  [BULK_ASYNC_JOB_MESSAGES.skipped]: 'Skipped',
  [BULK_ASYNC_JOB_MESSAGES.no_spots_available]: 'No spots available',
  [BULK_ASYNC_JOB_MESSAGES.waitlisted]: 'Waitlisted',
  [BULK_ASYNC_JOB_MESSAGES.enrolled]: 'Enrolled',
  [BULK_ASYNC_JOB_MESSAGES.exception_raised]: 'Internal error',
};
