import { InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import TextInput from 'inputs/components/TextInput';
import colors from 'services/colors';
import Icon from 'shared/components/Icon';
import IconButton from 'shared/components/IconButton';
import { isEmpty } from 'vendor/lodash';

const ICON_SIZE = 16;

export const SearchInput = ({ value, onChange, ...props }) => {
  const handleTextChange = (e) => {
    onChange(e.currentTarget.value);
  };

  const handleClearText = () => {
    onChange('');
  };

  return (
    <TextInput
      onChange={handleTextChange}
      value={value}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {isEmpty(value) ? (
              <Icon height={ICON_SIZE} width={ICON_SIZE} name="search" color={colors.neutral500} />
            ) : (
              <IconButton
                height={ICON_SIZE}
                width={ICON_SIZE}
                iconName="close"
                onClick={handleClearText}
                color={colors.neutral500}
              />
            )}
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

SearchInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default SearchInput;
