import React from 'react';
import { useResizeDetector } from 'react-resize-detector';

import LinkHover from 'shared/components/LinkHover';
import { isNil } from 'vendor/lodash';
import { Box } from 'vendor/mui';

interface Props {
  coverUrl: string;
  url?: string;
  onClick?: () => void;
}

const CardCover = ({ coverUrl, url, onClick }: Props) => {
  const { ref, width } = useResizeDetector();
  const Hover = url ? ({ children }) => <LinkHover to={url}>{children}</LinkHover> : React.Fragment;
  return (
    <Box
      ref={ref}
      sx={{
        position: 'relative',
        widows: '100%',
        height: (width || 50) / 1.777,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        ...(isNil(onClick) ? {} : { cursor: 'pointer', '&:hover': { opacity: 0.8 } }),
      }}
      onClick={onClick}
    >
      <Hover>
        <Box
          sx={{
            background: `url('${coverUrl}') no-repeat`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%',
            height: '100%',
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
          }}
        />
      </Hover>
    </Box>
  );
};

export default CardCover;
