import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, Fields, formValueSelector, reduxForm } from 'redux-form';
import styled from 'styled-components';

import * as constants from 'catalog/constants';
import { parseResources } from 'content-resources/services';
import actions from 'entities/actions';
import { programSelector } from 'entities/selectors';
import CoverImageField from 'inputs/components/CoverImageField';
import DurationField, { validateDuration } from 'inputs/components/DurationField';
import FacilitatorField from 'inputs/components/FacilitatorField';
import InlineItemField from 'inputs/components/InlineItemField';
import InputLabel from 'inputs/components/InputLabel';
import TextEditorField, { TextEditorContainer } from 'inputs/components/OldTextEditorField';
import TagTypesInputFields from 'inputs/components/TagTypesInputFields';
import TextField from 'inputs/components/TextField';
import { toast } from 'notifications/components/NotificationCenter';
import { useSettingsSectionsList } from 'settings/hooks';
import { prepareSettingsValuesForSubmission } from 'settings/services';
import { FormFieldGroup } from 'shared/components/Form';
import NewContentForm from 'shared/components/NewContentForm';
import CompletionSection from 'shared/components/NewContentForm/CompletionSection';
import AccessControlSection from 'shared/components/NewContentForm/sections/AccessControlSection';
import ResourcesSection from 'shared/components/NewContentForm/sections/ResourcesSection';
import SurveysSection from 'shared/components/NewContentForm/sections/SurveysSection';
import Form from 'shared/components/OldForm';
import {
  useCurrentUser,
  useFormPreventTransition,
  useFormSelector,
  useLabels,
  useToggles,
} from 'shared/hooks';
import { buildCustomTopicsList, buildFlexibleTagsFieldNameList } from 'topics/services';
import { concat, get, isNil, map, pick, toLower, isEmpty, filter } from 'vendor/lodash';
import { onSubmitActions } from 'vendor/redux-form-submit-saga';

const validateRequired = Form.validations.required();

const CoverContainer = styled.div`
  display: flex;
`;

export const CodelabForm = ({
  form,
  invalid,
  change,
  initialValues,
  handleSubmit,
  error,
  submitting,
  topBarActionName,
  isEdit,
  backRoute,
  breadcrumbsItemList,
  selectedOfficeHour,
  isModalForm,
}) => {
  const [settingsSectionsList] = useSettingsSectionsList('codelab/form', form);

  const currentUser = useCurrentUser();
  useFormPreventTransition(form);

  const {
    toggle_allow_generic_link_embedding_on_snippet: toggleAllowGenericLinkEmbeddingOnSnippet,
  } = useToggles();

  const { label_codelab: labelCodelab, label_codelab_plural: labelCodelabPlural } = useLabels();

  const codelabName = useFormSelector(form, 'name');

  const handleAppendToEditor = (url) => {
    change('content_body', { type: 'link_embed', content: url });
  };

  const infoPanelText = `${labelCodelabPlural} are best suited for modeling technical tutorials. They can be embedded into tracks and individually assigned to people.`;

  const codelabSectionsList = [
    {
      id: 'inline',
      label: 'Inline Item',
      icon: 'inline',
      section: <InlineItemField />,
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'codelab-details',
      label: `${labelCodelab} Details`,
      icon: 'info',
      section: (
        <>
          <FormFieldGroup>
            <Field
              name="name"
              label="Title"
              required
              component={TextField}
              placeholder={`Give your ${toLower(labelCodelab)} a title`}
              validate={[validateRequired]}
            />
          </FormFieldGroup>
          <FormFieldGroup>
            <CoverContainer>
              <Field
                name="cover"
                component={CoverImageField}
                imageWidth="412px"
                imageHeight="231px"
                filePath="codelab_covers"
              />
            </CoverContainer>
          </FormFieldGroup>
          <FormFieldGroup>
            <InputLabel htmlFor="content_body">Description</InputLabel>
            <TextEditorContainer>
              <Field
                name="content_body"
                component={TextEditorField}
                allowGenericLinks
                allowImageButton
                allowCloudDocButton
                allowVideoButton
                allowWebsiteButton={toggleAllowGenericLinkEmbeddingOnSnippet}
                handleAppendToEditor={handleAppendToEditor}
              />
            </TextEditorContainer>
          </FormFieldGroup>
          <FormFieldGroup>
            <Field
              inputId="duration"
              label="Estimated Duration (H:MM)"
              name="duration"
              component={DurationField}
              validate={[validateDuration]}
            />
          </FormFieldGroup>
          <Fields
            useNewFormFieldLabel
            names={['tags', ...buildFlexibleTagsFieldNameList(currentUser, ['toggle_codelabs'])]}
            component={TagTypesInputFields}
            currentUser={currentUser}
            toggleTypes={['toggle_codelabs']}
          />
        </>
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'people',
      label: 'People',
      icon: 'persons',
      section: <FacilitatorField label="Maintainer(s)" />,
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'completion',
      label: `Completion`,
      icon: 'clock',
      section: <CompletionSection form={form} labelContentType={labelCodelab} change={change} />,
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'resources',
      label: `Resources`,
      icon: 'teach',
      section: <ResourcesSection formName={form} selectedOfficeHour={selectedOfficeHour} />,
      sectionProps: {
        defaultOpen: true,
      },
    },
  ];

  const advancedSettingsList = [
    {
      id: 'surveys',
      label: 'Surveys',
      icon: 'survey',
      section: (
        <SurveysSection
          contentType={constants.CONTENT_TYPES.codelab}
          formName={form}
          assignmentsCount={initialValues.assignments_count}
          isEditing={isEdit}
        />
      ),
      sectionProps: {
        defaultOpen:
          !isEmpty(initialValues.survey_relationships) || !isEmpty(initialValues.survey_link),
      },
    },
    {
      id: 'access-control',
      label: 'Access Control',
      icon: 'lock',
      section: (
        <AccessControlSection
          showHideEnrolleesSection={false}
          contentNameSingular={labelCodelab}
          accessLevelFieldsNamesList={['is_hidden', 'groups_ids']}
        />
      ),
      sectionProps: {
        defaultOpen: initialValues.is_hidden || !isEmpty(initialValues.groups_ids),
      },
    },
    ...settingsSectionsList,
  ];

  return (
    <NewContentForm
      contentNameSingular={labelCodelab}
      contentInfoPanelText={infoPanelText}
      invalid={invalid}
      handleSubmit={handleSubmit}
      error={error}
      change={change}
      submitting={submitting}
      contentTitle={codelabName}
      topBarActionName={topBarActionName}
      isEdit={isEdit}
      backRoute={backRoute}
      breadcrumbsItemList={breadcrumbsItemList}
      contentSectionsList={codelabSectionsList}
      advancedSettingsList={advancedSettingsList}
      isModalForm={isModalForm}
    />
  );
};

CodelabForm.defaultProps = {
  initialValues: {},
};

CodelabForm.propTypes = {
  form: PropTypes.string,

  // Redux Form props
  invalid: PropTypes.bool,
  error: PropTypes.object,
  submitting: PropTypes.bool,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,

  initialValues: PropTypes.object,
  isEdit: PropTypes.bool,

  breadcrumbsItemList: PropTypes.arrayOf(PropTypes.object),

  topBarActionName: PropTypes.string,
  backRoute: PropTypes.string,

  selectedOfficeHour: PropTypes.object,
  isModalForm: PropTypes.bool,
};

const ConnectedCodelabForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  onSubmit: (values, dispatch, { isEdit, formName, currentUser, initialValues = {} }) => {
    return onSubmitActions(actions.codelab.update, (values) => {
      const payload = {
        ...pick(values, [
          'content_body',
          'cover',
          'duration',
          'facilitators_ids',
          'groups_ids',
          'hide_attendees',
          'is_hidden',
          'is_inline',
          'name',
          'office_hour_id',
          'resources',
          'survey_link',
          'survey_relationships',
          'tags',
          'time_to_complete',
          'time_to_retake',
        ]),
      };

      const customTopicsList = buildCustomTopicsList({
        user: currentUser,
        toggleTypes: ['toggle_codelabs'],
        flexibleTags: pick(
          values,
          map(
            currentUser?.custom_tags.flexible_filter_tags,
            (flexibleTag) => flexibleTag.filter_field_name
          )
        ),
        tags: values.tags,
      });

      const tags = get(payload, 'tags') && !isNil(payload.tags[0]) ? payload.tags : [];

      payload.tags = filter(concat(tags, customTopicsList), Boolean);
      payload.facilitators = map(payload.facilitators_ids, (id) => ({
        user_id: id,
        role: constants.FACILITATOR_ROLES.author,
      }));

      // Surveys
      let { survey_relationships: surveyRelationships } = payload;
      const { survey_link: surveyLink } = payload;

      surveyRelationships = map(surveyRelationships, (sr) => {
        const surveyRelationship = pick(sr, [
          'survey_id',
          'survey_cutoff_period_value',
          'survey_cutoff_period_option',
          'survey_schedule_period_value',
          'survey_schedule_period_option',
          'survey_schedule_kind',
        ]);

        if (isEdit) surveyRelationship.id = sr.id;

        return surveyRelationship;
      });

      payload.survey_link = surveyLink;
      payload.survey_relationships = surveyRelationships;

      // Resources Links
      payload.resources = parseResources(payload.resources, isEdit);

      if (!payload.duration) {
        payload.duration = '00:00:00';
      }

      // Settings
      payload.settings = prepareSettingsValuesForSubmission(values?.settings);

      return {
        ...(initialValues.public_id && { id: initialValues.public_id }),
        key: formName,
        body: payload,
      };
    })(values, dispatch);
  },
  onSubmitSuccess: (result, dispatch, { onSubmitSuccessHandler }) => onSubmitSuccessHandler(result),
  onSubmitFail: () => {
    window.scrollTo(0, 0);
    toast.error(`Oops. Could not submit the form. Check the flagged fields and try again.`);
  },
})(CodelabForm);

const mapStateToProps = (state, { form }) => {
  const formSelector = formValueSelector(form);
  const selectedOfficeHour = programSelector(state, formSelector(state, 'office_hour_id'), null);
  return {
    selectedOfficeHour,
  };
};

export default connect(mapStateToProps)(ConnectedCodelabForm);
