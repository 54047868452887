import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import actions from 'entities/actions';
import { enrollmentSchema } from 'entities/schema';
import { getDataFromState } from 'entities/utils';
import DoubleEnrollPerTypeWarnModal from 'event-shared/components/DoubleEnrollPerTypeWarnModal';
import {
  ENROLLMENT_STATUS_GOING,
  ENROLLMENT_STATUS_GOING_ONLINE,
  ENROLLMENT_STATUS_WAIT_LIST,
  ENROLLMENT_STATUS_NOT_GOING,
  ENROLLMENT_STATUS_EXIT_WAIT_LIST,
  UNENROLLMENT_POLICY_UNENROLLMENT_IS_FINAL,
  ENROLLMENT_STATUS_ATTENDED,
  ENROLLMENT_STATUS_WAIT_LIST_ONLINE,
  DOUBLE_ENROLLMENT_PER_TYPE_NOT_GRANTED_EXCEPTION_NAME,
  CANT_UNENROLL_EVENT_OF_SCHEDULED_TRACK_CODE,
} from 'event-shared/constants';
import MessageModal from 'notifications/components/MessageModal';
import { toast } from 'notifications/components/NotificationCenter';
import { DropEnrollmentModal } from 'scenes/EventDetail/EventActionButtons/DropEnrollmentModal';
import ModalManager from 'shared/components/ModalManager';
import { STATUS_LOADING, STATUS_ERROR, STATUS_DONE } from 'shared/constants';
import UnenrollScheduledTrackEventModal from 'tracks/components/ScheduleTrackModal/UnenrollScheduledTrackEventModal';
import { get, includes } from 'vendor/lodash';

import DeregistrationModal from './DeregistrationModal';
import ExternalWaitlistDeregistrationModal from './ExternalWaitlistDeregistrationModal';
import ExternalWaitlistModal from './ExternalWaitlistModal';
import RegistrationModal from './RegistrationModal';
import WarnDoubleBookingModal from './WarnDoubleBookingModal';

const ERROR_MODAL_MESSAGE = 'Error, refresh and try again';

export class EventRegistrationManager extends React.Component {
  constructor(props) {
    super(props);

    this.registrationModalManager = React.createRef();
    this.deregistrationModalManager = React.createRef();
    this.externalWaitListModalManager = React.createRef();
    this.externalWaitListDeregistrationModalManager = React.createRef();
    this.errorModalManager = React.createRef();
    this.doubleBookingModal = React.createRef();
    this.unenrollScheduledTrackEventModalManager = React.createRef();
    this.dropEnrollmentModalManager = React.createRef();

    this.state = {
      requestedStatusChange: null,
      status: null,
      showDoubleEnrollPerTypeWarnModal: false,
      scheduledTrackId: null,
      scheduledTrackName: null,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      updateEnrollmentRequestStatus,
      checkAvailabilityRequestStatus,
      checkAvailabilityRequestResponse,
      event,
      toggleEventExternalUnenrollmentPolicy,
    } = this.props;
    const { requestedStatusChange, status } = this.state;

    const noUnenrollFlow =
      toggleEventExternalUnenrollmentPolicy === UNENROLLMENT_POLICY_UNENROLLMENT_IS_FINAL;
    const isUnenrolling = includes(
      [ENROLLMENT_STATUS_NOT_GOING, ENROLLMENT_STATUS_EXIT_WAIT_LIST],
      status
    );

    const ERROR_OCCURRED =
      prevProps.updateEnrollmentRequestStatus === STATUS_LOADING &&
      updateEnrollmentRequestStatus === STATUS_ERROR;

    if (ERROR_OCCURRED) {
      this.handleErrorModal();
      return;
    }

    // Close unenroll modal if the request succeed (i.e. with no errors)
    this.dropEnrollmentModalManager.current?.hide();

    const userHasCheckedIn =
      prevProps.updateEnrollmentRequestStatus === STATUS_LOADING &&
      updateEnrollmentRequestStatus === STATUS_DONE &&
      status === ENROLLMENT_STATUS_ATTENDED;

    if (userHasCheckedIn) {
      toast.success('Success', 'You are checked in!');
    }

    // Check if the enrollment request was successful before opening the event external link in a new tab
    let checkedOpenEventExternalLink =
      event.requires_external_registration &&
      event.external_link &&
      prevProps.updateEnrollmentRequestStatus === STATUS_LOADING &&
      updateEnrollmentRequestStatus === STATUS_DONE;
    // In case of unenrollment, the external link will not be redirect if the unenrollment policy unenrollment is final
    checkedOpenEventExternalLink = isUnenrolling
      ? checkedOpenEventExternalLink && !noUnenrollFlow
      : checkedOpenEventExternalLink;

    if (checkedOpenEventExternalLink) {
      window.open(event.external_link, '_blank');
    }

    if (!requestedStatusChange) {
      return;
    }

    const checkedGCAvailability =
      prevProps.checkAvailabilityRequestStatus === STATUS_LOADING &&
      checkAvailabilityRequestStatus !== STATUS_LOADING;

    if (checkedGCAvailability) {
      const isAvailable = get(checkAvailabilityRequestResponse, 'available', true);
      if (isAvailable) {
        this.executeStatusChangeFlow(requestedStatusChange);
      } else {
        this.doubleBookingModal.current?.show();
      }
    }
  }

  handleErrorModal = () => {
    const { updateEnrollmentRequestError } = this.props;
    if (
      get(updateEnrollmentRequestError, 'exception') ===
      DOUBLE_ENROLLMENT_PER_TYPE_NOT_GRANTED_EXCEPTION_NAME
    ) {
      this.setState({ showDoubleEnrollPerTypeWarnModal: true });
      return;
    }

    if (get(updateEnrollmentRequestError, 'code') === CANT_UNENROLL_EVENT_OF_SCHEDULED_TRACK_CODE) {
      this.setState({
        scheduledTrackId: get(updateEnrollmentRequestError, 'scheduled_track_id'),
        scheduledTrackName: get(updateEnrollmentRequestError, 'scheduled_track_name'),
      });
      this.unenrollScheduledTrackEventModalManager.current?.show();
      this.dropEnrollmentModalManager.current?.hide();
      return;
    }

    this.errorModalManager.current?.show();
  };

  updateEnrollmentStatus = (status, params = {}) => {
    const { updateEnrollment } = this.props;

    let newStatus = status;
    if (status === ENROLLMENT_STATUS_EXIT_WAIT_LIST) {
      newStatus = ENROLLMENT_STATUS_NOT_GOING;
    }

    updateEnrollment({ status: newStatus, ...params });
  };

  externalRegistrationFlow(newStatus) {
    const { toggleEventExternalUnenrollmentPolicy } = this.props;

    const noUnenrollFlow =
      toggleEventExternalUnenrollmentPolicy === UNENROLLMENT_POLICY_UNENROLLMENT_IS_FINAL;
    const isUnenrolling = includes(
      [ENROLLMENT_STATUS_NOT_GOING, ENROLLMENT_STATUS_EXIT_WAIT_LIST],
      newStatus
    );

    if (isUnenrolling && noUnenrollFlow) {
      this.updateEnrollmentStatus(newStatus);
    }

    switch (newStatus) {
      case ENROLLMENT_STATUS_GOING:
        this.registrationModalManager.current?.show();
        break;
      case ENROLLMENT_STATUS_GOING_ONLINE:
        this.registrationModalManager.current?.show();
        break;
      case ENROLLMENT_STATUS_NOT_GOING:
        if (noUnenrollFlow) break;
        this.deregistrationModalManager.current?.show();
        break;
      case ENROLLMENT_STATUS_WAIT_LIST:
        this.externalWaitListModalManager.current?.show();
        break;
      case ENROLLMENT_STATUS_WAIT_LIST_ONLINE:
        this.externalWaitListModalManager.current?.show();
        break;
      case ENROLLMENT_STATUS_EXIT_WAIT_LIST:
        if (noUnenrollFlow) break;
        this.externalWaitListDeregistrationModalManager.current?.show();
        break;
      case ENROLLMENT_STATUS_ATTENDED:
        this.updateEnrollmentStatus(newStatus);
        break;
      default:
        break;
    }
  }

  executeStatusChangeFlow = (newStatus) => {
    const { event } = this.props;
    this.setState({ status: newStatus });

    if (event.requires_external_registration) {
      this.externalRegistrationFlow(newStatus);
    } else {
      this.updateEnrollmentStatus(newStatus);
    }
  };

  checkGCAvailability = () => {
    const { event, checkAvailability } = this.props;

    checkAvailability({ event_id: event.id });
  };

  registrationStatusChanged(newStatus) {
    const { toggleCheckAvailabilityBeforeEnrolling } = this.props;

    const isEnrolling = includes(
      [ENROLLMENT_STATUS_GOING, ENROLLMENT_STATUS_GOING_ONLINE, ENROLLMENT_STATUS_WAIT_LIST],
      newStatus
    );
    const isUnenrolling = includes(
      [ENROLLMENT_STATUS_NOT_GOING, ENROLLMENT_STATUS_EXIT_WAIT_LIST],
      newStatus
    );

    if (isEnrolling && toggleCheckAvailabilityBeforeEnrolling) {
      this.setState({ requestedStatusChange: newStatus });
      this.checkGCAvailability();
      return;
    }

    if (isUnenrolling) {
      this.dropEnrollmentModalManager.current?.show();
      return;
    }

    this.executeStatusChangeFlow(newStatus);
  }

  render() {
    const {
      event,
      updateEnrollmentRequestResponse,
      updateEnrollmentRequestError,
      userTimezone,
      updateEnrollmentRequestStatus,
    } = this.props;
    const {
      requestedStatusChange,
      status,
      showDoubleEnrollPerTypeWarnModal,
      scheduledTrackId,
      scheduledTrackName,
    } = this.state;

    const limitDoubleBooking = event.event_type ? event.event_type.limit_double_booking : false;

    return (
      <>
        <ModalManager ref={this.registrationModalManager}>
          <RegistrationModal
            event={event}
            status={status}
            userTimezone={userTimezone}
            updateEnrollmentStatus={this.updateEnrollmentStatus}
          />
        </ModalManager>
        <ModalManager ref={this.deregistrationModalManager}>
          <DeregistrationModal event={event} updateEnrollmentStatus={this.updateEnrollmentStatus} />
        </ModalManager>
        <ModalManager ref={this.externalWaitListModalManager}>
          <ExternalWaitlistModal
            event={event}
            status={status}
            updateEnrollmentStatus={this.updateEnrollmentStatus}
          />
        </ModalManager>
        <ModalManager ref={this.externalWaitListDeregistrationModalManager}>
          <ExternalWaitlistDeregistrationModal
            event={event}
            updateEnrollmentStatus={this.updateEnrollmentStatus}
          />
        </ModalManager>
        <ModalManager ref={this.unenrollScheduledTrackEventModalManager}>
          <UnenrollScheduledTrackEventModal
            event={event}
            userTimezone={userTimezone}
            updateEnrollmentStatus={this.updateEnrollmentStatus}
            scheduledTrackId={scheduledTrackId}
            scheduledTrackName={scheduledTrackName}
          />
        </ModalManager>
        <ModalManager ref={this.dropEnrollmentModalManager}>
          <DropEnrollmentModal
            event={event}
            performUnenroll={() => this.updateEnrollmentStatus(ENROLLMENT_STATUS_NOT_GOING)}
          />
        </ModalManager>
        {limitDoubleBooking && showDoubleEnrollPerTypeWarnModal ? (
          <DoubleEnrollPerTypeWarnModal
            prospectEvent={event}
            prospectEnrollmentStatus={status}
            updateEnrollmentStatus={this.updateEnrollmentStatus}
            updateEnrollmentRequestStatus={updateEnrollmentRequestStatus}
            handleClose={() => this.setState({ showDoubleEnrollPerTypeWarnModal: false })}
          />
        ) : (
          <ModalManager ref={this.errorModalManager}>
            <MessageModal
              title="ERROR!"
              message={
                get(updateEnrollmentRequestResponse, 'error') ||
                get(updateEnrollmentRequestError, 'error') ||
                ERROR_MODAL_MESSAGE
              }
            />
          </ModalManager>
        )}
        <ModalManager ref={this.doubleBookingModal}>
          <WarnDoubleBookingModal
            requestedStatus={requestedStatusChange}
            updateEnrollmentStatus={this.executeStatusChangeFlow}
          />
        </ModalManager>
      </>
    );
  }
}

EventRegistrationManager.propTypes = {
  event: PropTypes.object,

  userTimezone: PropTypes.string,

  updateEnrollment: PropTypes.func,
  updateEnrollmentRequestResponse: PropTypes.object,
  updateEnrollmentRequestStatus: PropTypes.string,
  updateEnrollmentRequestError: PropTypes.object,

  checkAvailability: PropTypes.func,
  checkAvailabilityRequestResponse: PropTypes.object,
  checkAvailabilityRequestStatus: PropTypes.string,

  toggleCheckAvailabilityBeforeEnrolling: PropTypes.bool,
  toggleEventExternalUnenrollmentPolicy: PropTypes.string,
};

const mapStateToProps = (state, { event }) => {
  const updateEnrollmentRequestState = getDataFromState(
    `enrollmentStatusUpdate${event.public_id}`,
    state,
    enrollmentSchema
  );
  const checkAvailabilityRequestState = getDataFromState(`freeBusyCheck${event.id}`, state);

  return {
    userTimezone: state.user.currentUser.timezone,

    updateEnrollmentRequestResponse: updateEnrollmentRequestState.data,
    updateEnrollmentRequestStatus: updateEnrollmentRequestState.status,
    updateEnrollmentRequestError: updateEnrollmentRequestState.error,

    checkAvailabilityRequestResponse: checkAvailabilityRequestState.data,
    checkAvailabilityRequestStatus: checkAvailabilityRequestState.status,

    toggleEventExternalUnenrollmentPolicy:
      state.user.currentUser.toggles.toggle_event_external_unenrollment_policy,
    toggleCheckAvailabilityBeforeEnrolling:
      state.user.currentUser.check_google_calendar_double_bookings,
  };
};

const mapDispatchToProps = (dispatch, { event }) => ({
  updateEnrollment: (body) =>
    dispatch(
      actions.eventEnrollment.updateSubmit(
        `enrollmentStatusUpdate${event.public_id}`,
        event.public_id,
        {
          ...body,
        }
      )
    ),
  checkAvailability: (params) =>
    dispatch(actions.googleCalendar.freeBusyCheck(`freeBusyCheck${event.id}`, params)),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  EventRegistrationManager
);
