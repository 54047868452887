import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Rating } from 'shared';
import ButtonLink from 'shared/components/ButtonLink';
import RateButton from 'shared/components/RateButton';
import { useCurrentUser } from 'shared/hooks';
import { get } from 'vendor/lodash';

import EventFeedbackModal from '../EventFeedbackModal';

const RatingItemWrapper = styled.div`
  display: flex;

  > * + * {
    margin-left: 6px;
  }
`;

const RatingItem = ({ rating, onEditClick }) => {
  return (
    <RatingItemWrapper>
      <Rating value={rating} readOnly />
      <ButtonLink onClick={onEditClick}>Edit</ButtonLink>
    </RatingItemWrapper>
  );
};

RatingItem.propTypes = {
  rating: PropTypes.number,
  onEditClick: PropTypes.func,
};

export const RatingActionButtons = ({ event }) => {
  const { enrollment } = event;

  const feedbackText = get(enrollment, 'feedback_text');
  const feedbackRating = get(enrollment, 'feedback_rating');

  const currentUser = useCurrentUser();
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const getRateButton = () => {
    if (feedbackRating && feedbackRating !== 0) {
      return <RatingItem rating={feedbackRating} onEditClick={() => setShowFeedbackModal(true)} />;
    }
    return (
      <RateButton size="medium" onClick={() => setShowFeedbackModal(true)}>
        Rate
      </RateButton>
    );
  };

  return (
    <>
      {getRateButton()}
      {showFeedbackModal && (
        <EventFeedbackModal
          publicId={event.public_id}
          user={currentUser}
          feedbackText={feedbackText}
          feedbackRating={feedbackRating}
          handleClose={() => setShowFeedbackModal(false)}
        />
      )}
    </>
  );
};

RatingActionButtons.propTypes = {
  event: PropTypes.object,
};
