import { MouseEventHandler, useState } from 'react';
import { useHistory } from 'react-router';

import TextInput from 'inputs/components/TextInput';
import { mapRoute } from 'services/requests';
import { Text, formValidations } from 'shared';
import HelpLink from 'shared/components/HelpLink';
import Modal, { ModalBody, ModalFooter, ModalFooterButton } from 'shared/components/Modal';
import { isEmpty } from 'vendor/lodash';
import { Stack } from 'vendor/mui';

const NewLinkedContentModal = ({ handleClose }: { handleClose: MouseEventHandler }) => {
  const history = useHistory();
  const [url, setUrl] = useState('');
  const [error, setError] = useState<string | undefined>('');

  const onChange = (value: string) => {
    setUrl(value);

    setError(formValidations.url(value));
  };
  return (
    <Modal minBodyHeight={200} title="New Link" handleClose={handleClose} width={500}>
      <ModalBody>
        <Stack direction="column" spacing={2} mt={0.5}>
          <Text>
            Add external third-party content.
            <HelpLink url="http://help.plusplus.app/en/articles/7984993-linked-content" />
          </Text>
          <TextInput
            label="URL"
            inputProps={{
              name: 'message',
              maxLength: 200,
              value: url,
              onChange: (event) => onChange(event.target.value),
            }}
            error={Boolean(error)}
            helperText={error}
          />
        </Stack>
      </ModalBody>
      <ModalFooter variant="buttons" justifyContent="flexEnd">
        <ModalFooterButton type="button" variant="text" onClick={handleClose}>
          Cancel
        </ModalFooterButton>
        <ModalFooterButton
          type="button"
          disabled={Boolean(error) || isEmpty(url)}
          onClick={() =>
            history.push(`${mapRoute('linkedContentCreate')}?is_publishing=true&url=${url}`)
          }
        >
          Next
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  );
};

export default NewLinkedContentModal;
