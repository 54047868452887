import React from 'react';
import styled from 'styled-components';

import colors from 'services/colors';
import DropDownMenu from 'shared/components/DropDownMenu';
import Icon from 'shared/components/Icon';
import { useCurrentUser, useToggles } from 'shared/hooks';
import { map, isEmpty } from 'vendor/lodash';

const IconWrapper = styled.div`
  margin-top: 4px;
  margin-right: 12px;
`;

const SupportMenuItem = styled(DropDownMenu.Item)`
  min-width: 180px;
  font-weight: normal !important;
  color: ${colors.neutral600} !important;
`;

const UpperCaseSupportMenuItem = styled(SupportMenuItem)`
  text-transform: uppercase;
  font-size: 12px;
`;

const MenuDivider = styled.li`
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: ${colors.neutral200};
`;

const SupportMenu = () => {
  const { support_menu_configuration: supportMenuConfiguration } = useCurrentUser();
  const { toggle_intercom: toggleIntercom } = useToggles();
  return (
    <DropDownMenu
      buttonContent={
        <IconWrapper>
          <Icon name="question-mark" color={colors.emphasis600} height={28} width={28} />
        </IconWrapper>
      }
    >
      {map(supportMenuConfiguration, ({ label, url }, i) => {
        return <SupportMenuItem key={i} url={url} target="_blank" title={label} />;
      })}

      {!isEmpty(supportMenuConfiguration) && <MenuDivider key={55} />}

      <UpperCaseSupportMenuItem
        fontSize={12}
        url={toggleIntercom ? 'https://help.plusplus.app/' : 'https://plusplus.co/support/'}
        target="_blank"
        title={toggleIntercom ? 'PlusPlus Help Center' : 'Software Support'}
      />
    </DropDownMenu>
  );
};

export default SupportMenu;
