import * as React from 'react';

import { useEncodedCurrentRoute } from 'app/shared/hooks';
import { mapRoute } from 'services/requests';

type UseChannelRoutesOptions = {
  withOriginRoute?: boolean;
};

export function useChannelRoutes(options: UseChannelRoutesOptions = {}) {
  const { withOriginRoute = false } = options;

  const currentRoute = useEncodedCurrentRoute();

  const withOrigin = (path) => (withOriginRoute ? `${path}?origin=${currentRoute}` : path);

  const routes = React.useMemo(
    () => ({
      create: withOrigin(mapRoute('channelCreate')),
      detail: ({ public_id_and_slug }) =>
        withOrigin(mapRoute('channelDetail', { public_id_and_slug })),
      edit: ({ public_id_and_slug }) => withOrigin(mapRoute('channelEdit', { public_id_and_slug })),
      addContent: ({ public_id_and_slug }) =>
        withOrigin(mapRoute('channelAddContent', { public_id_and_slug })),
    }),
    [currentRoute, withOrigin]
  );

  return routes;
}
