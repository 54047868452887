import PropTypes from 'prop-types';
import React from 'react';
import { useResizeDetector } from 'react-resize-detector';
import styled from 'styled-components';

import { AssignmentsActionsProvider } from 'app/assignments/Context';
import { Button } from 'app/shared';
import { LEARNING_TYPE_ICONS_MAPPING, LEARNING_TYPES } from 'catalog/constants';
import RelatedEvents from 'event-shared/components/RelatedEvents';
import BasicInfo from 'event-types/components/EventTypeWidget/BasicInfo';
import RequestEventModalWrapper from 'event-types/components/RequestEventModal';
import ContentItemContextMenu from 'scenes/ContentItemContextMenu/ContentItemContextMenu';
import PrimaryActionButton from 'scenes/EventTypeDetail/PrimaryActionButton';
import colors from 'services/colors';
import { mapRoute } from 'services/requests';
import SecondaryActionButton from 'shared-content-item/components/SecondaryActionButton';
import ButtonLink from 'shared/components/ButtonLink';
import Icon from 'shared/components/Icon';
import MediaPoint from 'shared/components/MediaPoint';
import Text from 'shared/components/Text';
import { useCurrentUser, useToggles } from 'shared/hooks';
import * as permissionConstants from 'shared/permissions';
import ContentDescription from 'stand-alone-shared/components/ContentDescription';
import { includes, get } from 'vendor/lodash';

const ContentContainer = styled.div`
  ${({ insideTrack, theme }) =>
    insideTrack &&
    `
      overflow: hidden;

      ${MediaPoint.DesktopLg} {
        height: 770px;
      }

      ${DescriptionContainer} {
        ${theme.mixins.gradientMask(60)}
      }
      `}
`;

const DetailContainer = styled.div`
  background-color: white;
  border-radius: 10px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
`;

const TitleHeader = styled.h1`
  margin: 20px 0;
  ${({ insideTrack }) => insideTrack && `font-size: 28px;`};
`;

const ContentDetailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DetailsContainer = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.neutral100};

  display: flex;
  flex-direction: column;

  ${MediaPoint.DesktopSm} {
    width: 38.3%;
  }
  ${({ insideTrack }) =>
    insideTrack &&
    `
    display: none;
    ${MediaPoint.DesktopSm} {
      display: flex;
    }
  `};
`;

const UpcomingEvents = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
`;

const UpcomingEventsCount = styled.div`
  margin: 20px 20px 8px;
`;

const RelatedEventsContainer = styled.div`
  overflow-y: auto;

  ${({ insideTrack }) => insideTrack && `height: 398px;`};
`;

const CoverDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${MediaPoint.DesktopSm} {
    flex: 0 0 61.7%;
  }
`;

const CoverContainer = styled.div`
  background-color: ${colors.learningEventType600};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(props) => props.width / 1.777}px;
  position: relative;
`;

const CoverImage = styled.div`
  background: url('${({ imageUrl }) => imageUrl}') no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
`;

const DescriptionContainer = styled.div`
  margin: 16px 0 16px;
`;

const ActionButtonWrapper = styled.div`
  margin: 8px 20px 20px;

  > * {
    margin-top: 16px;
  }
`;

// TODO remove insideTrack after the old tracks page is replaced
const EventTypeWidget = ({ eventType, insideTrack, clickableName }) => {
  const user = useCurrentUser();
  const { ref, width } = useResizeDetector();
  const { toggle_request_event: toggleRequestEvent } = useToggles();

  const canEditEventType = includes(
    eventType.permissions,
    permissionConstants.EDIT_EVENT_TYPE_PERMISSION
  );
  const canCreateEvent = includes(user.permissions, permissionConstants.CREATE_EVENT_PERMISSION);
  const tagList = eventType.tags;

  const LinkComponent = insideTrack || clickableName ? ButtonLink : React.Fragment;
  return (
    <ContentContainer insideTrack={insideTrack}>
      <DetailContainer>
        <HeaderContainer>
          <LinkComponent
            {...((insideTrack || clickableName) && {
              route: mapRoute('eventTypeDetails', {
                public_id_and_slug: eventType.public_id_and_slug,
              }),
              variant: insideTrack ? 'dark' : clickableName && 'primary',
              target: '_blank',
            })}
          >
            <TitleHeader insideTrack={insideTrack}>{eventType.name}</TitleHeader>
          </LinkComponent>

          {/* Do not show for old track page */}
          {!insideTrack && canEditEventType ? (
            <ContentItemContextMenu content={eventType} />
          ) : (
            !insideTrack &&
            canCreateEvent &&
            eventType.public_id && (
              <Button
                icon={<Icon name="plus" />}
                size="small"
                route={mapRoute('eventNewFromTemplate', {
                  public_id: eventType.public_id,
                })}
              >
                Schedule
              </Button>
            )
          )}
        </HeaderContainer>
        <ContentDetailsContainer>
          <CoverDescriptionContainer>
            <CoverContainer ref={ref} width={width}>
              {!eventType.cover ? (
                <Icon
                  name={LEARNING_TYPE_ICONS_MAPPING[LEARNING_TYPES.event_types]}
                  width={100}
                  height={100}
                  color={colors.neutral900}
                />
              ) : (
                <CoverImage imageUrl={eventType.cover} />
              )}
            </CoverContainer>
            <DescriptionContainer>
              <ContentDescription description={eventType.content_body} />
            </DescriptionContainer>
          </CoverDescriptionContainer>
          <DetailsContainer insideTrack={insideTrack}>
            <BasicInfo eventType={eventType} tagList={tagList} user={user} />
            <UpcomingEvents>
              <UpcomingEventsCount>
                <Text size="h4" lineHeight={20} medium>
                  {eventType.upcoming_events_count} upcoming{' '}
                  {eventType.upcoming_events_count === 1 ? 'event' : 'events'}
                </Text>
              </UpcomingEventsCount>
              {toggleRequestEvent && (
                <ActionButtonWrapper>
                  <RequestEventModalWrapper eventType={eventType} size="medium" fullWidth />
                </ActionButtonWrapper>
              )}
              <ActionButtonWrapper>
                <AssignmentsActionsProvider
                  content={eventType}
                  assignmentId={get(eventType, 'assignment.id')}
                >
                  <SecondaryActionButton
                    content={eventType}
                    contentType={LEARNING_TYPES.event_types}
                  />

                  <PrimaryActionButton content={eventType} />
                </AssignmentsActionsProvider>
              </ActionButtonWrapper>

              <RelatedEventsContainer insideTrack={insideTrack}>
                <RelatedEvents
                  eventTypeId={eventType.id}
                  eventTypePublicIdAndSlug={eventType.public_id_and_slug}
                  includePrivate={eventType.is_hidden}
                  showEnrollmentAction
                  insideTrack={insideTrack}
                />
              </RelatedEventsContainer>
            </UpcomingEvents>
          </DetailsContainer>
        </ContentDetailsContainer>
      </DetailContainer>
    </ContentContainer>
  );
};

EventTypeWidget.defaultProps = {
  clickableName: false,
};

EventTypeWidget.propTypes = {
  eventType: PropTypes.object,
  insideTrack: PropTypes.bool,
  clickableName: PropTypes.bool,
};

export default EventTypeWidget;
