import { ReactNode } from 'react';

import { Box } from 'vendor/mui';

interface Props {
  children?: ReactNode;
}

export const CardBase = ({ children }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 480,
        borderRadius: 1,
        backgroundColor: 'white',
      }}
    >
      {children}
    </Box>
  );
};

export default CardBase;
