import PropTypes from 'prop-types';

import { CONTENT_TYPES } from 'catalog/constants';
import EventCard from 'events/components/EventCard';
import SessionCard from 'features/sessions/components/SessionCard';
import { get, map } from 'vendor/lodash';

import { ContentItemCard } from './ContentItemCard';
import { EventTypeCard } from './EventTypeCard';
import { ProgramCard } from './ProgramCard';
import { TrackCard } from './TrackCard';

const OptimalContentItemCard = (props) => {
  const { contentItem, disableAllClicks } = props;

  switch (contentItem.content_type) {
    case CONTENT_TYPES.eventtype:
      return <EventTypeCard eventType={contentItem} disableAllClicks={disableAllClicks} />;
    case CONTENT_TYPES.program:
      return <ProgramCard program={contentItem} disableAllClicks={disableAllClicks} />;
    case CONTENT_TYPES.track:
      return <TrackCard track={contentItem} disableAllClicks={disableAllClicks} />;
    case CONTENT_TYPES.event:
      return <EventCard event={contentItem} shouldRefreshEvent />;
    case 'session':
      // Very old session components are expecting the list of attendees in the session itself, instead of using the enrollments field.
      return (
        <SessionCard
          session={{ ...contentItem, attendees: map(get(contentItem, 'enrollments'), 'attendee') }}
        />
      );
    default:
      return <ContentItemCard contentItem={contentItem} disableAllClicks={disableAllClicks} />;
  }
};

OptimalContentItemCard.propTypes = {
  contentItem: PropTypes.object,
  disableAllClicks: PropTypes.bool,
};

export { OptimalContentItemCard };
