import { useQueryClient, useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React from 'react';

import { ADD_CHANNEL_CONTENT_PERMISSION } from 'app/shared/permissions';
import ArticleForm from 'article/components/ArticleForm/ArticleForm';
import { useContentOnSubmitSuccessHandler } from 'catalog/hooks';
import { queries } from 'queries';
import PageTitle from 'shared/components/PageTitle/PageTitle';
import { INITIAL_DURATIONS } from 'shared/constants';
import { useCurrentUser, useHasChannelPermission } from 'shared/hooks';
import { sortCustomTags } from 'topics/services';
import { get, has, omit, slice } from 'vendor/lodash';

const getInitialValues = ({ currentUser, defaultSettings, clonedArticle, article, channel }) => {
  let initialValues = {
    ...article,
  };

  if (clonedArticle) {
    initialValues = omit(
      {
        ...clonedArticle,
        name: `Duplicate of ${clonedArticle.name}`,
      },
      ['public_id', 'public_id_and_slug']
    );
  }

  // Facilitators
  if (!has(initialValues, 'facilitators_ids')) {
    initialValues.facilitators_ids = [currentUser.id];
  }

  // Access control
  if (!has(initialValues, 'is_hidden')) {
    initialValues.is_hidden = false;
  }

  // If is_completable is not defined, set it as initially true
  if (!has(initialValues, 'is_completable')) {
    initialValues.is_completable = true;
  }

  // Default duration for Article is 10min
  if (!has(initialValues, 'duration')) {
    initialValues.duration = INITIAL_DURATIONS.article;
  }

  // If the duration comes as "zero", set it as null so the frontend
  // won't enforce the field to require a formatting even though it's empty
  if (initialValues.duration === '00:00:00') {
    initialValues.duration = null;
  }

  // Settings
  if (!has(initialValues, 'settings')) {
    initialValues.settings = defaultSettings;
  }

  if (!initialValues.channel_id && channel && channel.id) {
    initialValues.channel_id = channel.id;
  }

  const customTagsInitialValues = sortCustomTags(currentUser, initialValues, 'tags');

  return { ...initialValues, ...customTagsInitialValues };
};

const ArticleFormPage = ({
  pageTitle,
  topBarActionName,
  backRoute,
  breadcrumbsItemList,
  initialValuesParams,
  initialValuesParams: { article },
}) => {
  const currentUser = useCurrentUser();

  const { handleContentOnSubmitSuccessHandler } = useContentOnSubmitSuccessHandler();

  const queryClient = useQueryClient();

  const handleInvalidateArticleQuery = () => {
    const publicId = get(article, 'public_id');
    if (!publicId) {
      return;
    }

    let queryKey = queries.articles.detail(publicId).queryKey;
    // Last element is view mode, we don't need it
    queryKey = slice(queryKey, 0, -1);
    queryClient.invalidateQueries({ queryKey });
  };

  const canAddChannelContent = useHasChannelPermission(ADD_CHANNEL_CONTENT_PERMISSION);

  const { data: channel } = useQuery({
    ...queries.channels.list('view_mode=filter_options'),
    select: (data) => {
      const firstChannel = data?.results?.[0];
      return firstChannel ? { ...firstChannel, id: firstChannel.value } : { id: 0 }; // Default object if no channel is found
    },
    enabled: !!canAddChannelContent, // Fetch only if user has permission to add content to a channel
  });

  const initialValues = getInitialValues({
    currentUser,
    ...initialValuesParams,
    channel,
  });

  const isEdit = Boolean(article);
  const form = `newArticle${isEdit ? 'Edit' : 'Create'}Form`;

  return (
    <>
      <PageTitle title={pageTitle} />
      <ArticleForm
        form={form}
        initialValues={initialValues}
        topBarActionName={topBarActionName}
        isEdit={isEdit}
        backRoute={backRoute}
        breadcrumbsItemList={breadcrumbsItemList}
        onSubmitSuccessHandler={(result) => {
          if (isEdit) {
            handleInvalidateArticleQuery();
          }

          handleContentOnSubmitSuccessHandler({
            backRoute,
            isEdit,
            result,
          });
        }}
      />
    </>
  );
};

export default ArticleFormPage;

ArticleFormPage.defaultProps = {
  initialValuesParams: {},
};

ArticleFormPage.propTypes = {
  pageTitle: PropTypes.string,
  topBarActionName: PropTypes.string,
  backRoute: PropTypes.string,
  breadcrumbsItemList: PropTypes.arrayOf(PropTypes.object),
  initialValuesParams: PropTypes.object,
};
