import { FiltersDefinition, FiltersDefinitionProps } from 'filters/types';
import { getOrderedFiltersDefinition } from 'filters/utils';
import { useCurrentUser } from 'shared/hooks';
import { useMediaQuery } from 'vendor/mui';

import { useGetAllCatalogFiltersDefinition } from './useGetAllCatalogFiltersDefinition';

export const useCatalogFiltersDefinition = ({
  filters,
  ordering,
  updateFilter,
  setOrdering,
  fixedFilters,
  includeFilterChannels,
}: FiltersDefinitionProps): FiltersDefinition => {
  const currentUser = useCurrentUser();
  const isScreenMdDown = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  const defaultFiltersOrder = [
    'SearchBar',
    'Content Types',
    'Categories',
    'Sort',
    'Tags',
    'Ownership',
    'Status',
  ];

  const allFilters = useGetAllCatalogFiltersDefinition({
    filters,
    ordering,
    setOrdering,
    updateFilter,
  });

  return getOrderedFiltersDefinition({
    currentUser,
    filterOrderName: 'filter_order_catalog',
    defaultFiltersOrder,
    allFilters,
    filterWidth: '200px',
    fixedFilters,
    numberOfFiltersShownOutside: isScreenMdDown ? 0 : 2,
    includeFilterChannels,
  });
};

export default useCatalogFiltersDefinition;
