import { useHistory } from 'react-router';

import { toast } from 'notifications/components/NotificationCenter';
import PageTitle from 'shared/components/PageTitle/PageTitle';
import { useCurrentUser, useLabels } from 'shared/hooks';
import { has } from 'vendor/lodash';

import { useChannelRoutes } from '../hooks/useChannelRoutes';

import ChannelForm from './ChannelForm';

type ChannelFormPageProps = {
  pageTitle: string;
  topBarActionName: string;
  backRoute: string;
  breadcrumbsItemList: object[];
  initialValuesParams: any;
  onSubmit: any;
};

const getInitialValues = ({ currentUser, channel }) => {
  const initialValues = {
    ...channel,
  };

  // Owners
  if (!has(initialValues, 'owners_ids')) {
    initialValues.owners_ids = [currentUser.id];
  }

  return { ...initialValues };
};

const ChannelFormPage = (props: ChannelFormPageProps) => {
  const {
    pageTitle,
    topBarActionName,
    backRoute,
    onSubmit,
    breadcrumbsItemList,
    initialValuesParams,
    initialValuesParams: { channel },
  } = props;

  const history = useHistory();
  const currentUser = useCurrentUser();

  const { label_channel: labelChannel } = useLabels();

  const initialValues = getInitialValues({
    currentUser,
    ...initialValuesParams,
  });

  const isEdit = Boolean(channel);
  const form = `Channel${isEdit ? 'Edit' : 'Create'}Form`;
  const { detail: channelDetailRoute } = useChannelRoutes();
  const onSubmitHandler = async (values) => {
    const { public_id_and_slug } = await onSubmit.mutateAsync(values);
    return public_id_and_slug;
  };

  const onSubmitSuccessHandler = (public_id_and_slug, isEdit) => {
    toast.success(`${labelChannel} ${isEdit ? 'updated' : 'created'} successfully`);
    history.push(channelDetailRoute({ public_id_and_slug }));
  };

  return (
    <>
      <PageTitle title={pageTitle} />
      <ChannelForm
        form={form}
        initialValues={initialValues}
        topBarActionName={topBarActionName}
        isEdit={isEdit}
        backRoute={backRoute}
        breadcrumbsItemList={breadcrumbsItemList}
        onSubmitHandler={onSubmitHandler}
        onSubmitSuccessHandler={onSubmitSuccessHandler}
      />
    </>
  );
};

export default ChannelFormPage;
