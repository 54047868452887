import { ReactNode } from 'react';

import { Box } from 'vendor/mui';

interface Props {
  children?: ReactNode;
}

const CardFooter = ({ children }: Props) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: 45,
        display: 'flex',
        justifyContent: 'space-between',
        alignSelf: 'flex-end',
        alignItems: 'center',
        padding: '8px 16px 8px 16px',
      }}
    >
      {children}
    </Box>
  );
};

export default CardFooter;
