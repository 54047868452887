import { Session } from 'common/types';
import colors from 'services/colors';
import confirmAlert from 'services/confirm-alert';
import Button from 'shared/components/Button';
import { useCurrentUser } from 'shared/hooks';
import { get } from 'vendor/lodash';
import { Typography, styled, Divider } from 'vendor/mui';
import { LoadingButton } from 'vendor/mui-lab';

interface Props {
  session: Session;
  dropSession: () => void;
  isLoading: boolean;
}

const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 0 16px 0',
});

const SessionCardActions = ({ session, dropSession, isLoading }: Props) => {
  const currentUser = useCurrentUser();
  const {
    host,
    user_session_enrollment: enrollment,
    allows_online: isOnline,
    meeting_url: meetingUrl,
  } = session;
  const isHost = currentUser.id === host.id;
  const isAttendee = currentUser.id === get(enrollment, 'attendee_id');
  if (!isHost && !isAttendee) return null;
  return (
    <>
      <Divider sx={{ marginLeft: -2, marginRight: -2 }} />
      <Wrapper>
        {isOnline && (
          <Button size="small" href={meetingUrl || undefined} target="_blank">
            Join
          </Button>
        )}
        {!isOnline && (
          <Typography sx={{ color: colors.action700 }} variant="subtitle2">
            Enrolled In-Person
          </Typography>
        )}
        <LoadingButton
          color="error"
          size="small"
          loading={isLoading}
          loadingPosition="end"
          endIcon={<div />} // MUI requires an endIcon when the loadingPosition is "end"
          disabled={isLoading}
          variant="outlined"
          onClick={() =>
            isHost
              ? confirmAlert({
                  title: 'Delete Session?',
                  content: () => <span>Would you like to delete this session?</span>,
                  confirmLabel: 'Yes',
                  secondaryActionLabel: 'No',
                  onConfirm: () => dropSession(),
                  isDangerAction: true,
                })
              : dropSession()
          }
          sx={{ pr: isLoading ? 4 : 1, '& .MuiButton-endIcon': { m: 0 } }}
        >
          {isHost ? 'Delete' : 'Unenroll'}
        </LoadingButton>
      </Wrapper>
      <Divider sx={{ marginLeft: -2, marginRight: -2 }} />
    </>
  );
};

export default SessionCardActions;
