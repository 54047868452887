import React, { useEffect } from 'react';

import { LEARNING_TYPES } from 'catalog/constants';
import actions from 'entities/actions';
import { eventTypeSchema } from 'entities/schema';
import { useEntities } from 'entities/utils';
import { mapRoute } from 'services/requests';
import { useParentSettings } from 'settings/hooks';
import Loading from 'shared/components/Loading';
import { STATUS_LOADING } from 'shared/constants';
import { useOriginRoute, useQueryParams, useSetDetailedObject, useLabels } from 'shared/hooks';
import { capitalizeMultipleWordsName } from 'shared/services';
import { toLower, isEmpty } from 'vendor/lodash';

import EventTypeFormPage from './EventTypeFormPage';

const EventTypeCreate = () => {
  const parentSettingsFlat = useParentSettings('event_type/form', 'flat');

  const { cloned: duplicatedEventTypeId } = useQueryParams();
  const { label_event_type: labelEventType } = useLabels();
  const isDuplicating = Boolean(duplicatedEventTypeId);

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.event_types
  }&o=upcoming`;
  const backRoute = useOriginRoute(catalogRoute);

  const [fetchEventType, { data: clonedEventType, status }] = useEntities(
    actions.eventType.retrieveDetails,
    null,
    {
      schema: eventTypeSchema,
    }
  );

  useEffect(() => {
    if (duplicatedEventTypeId) {
      fetchEventType(duplicatedEventTypeId);
    }
  }, []);

  useSetDetailedObject({ id: null }, 'event_types', []);

  if (duplicatedEventTypeId && status === STATUS_LOADING) return <Loading />;

  const initialValuesParams = {};
  if (duplicatedEventTypeId) initialValuesParams.clonedEventType = clonedEventType;
  if (!duplicatedEventTypeId && !isEmpty(parentSettingsFlat))
    initialValuesParams.settings = parentSettingsFlat;

  const capitalizedLabelEventType = capitalizeMultipleWordsName(labelEventType);

  return (
    <EventTypeFormPage
      pageTitle={`${isDuplicating ? 'Duplicate' : 'Create'} ${capitalizedLabelEventType}`}
      topBarActionName={`${
        isDuplicating ? 'Duplicating' : 'Creating'
      } ${capitalizedLabelEventType}`}
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: `${capitalizedLabelEventType}s`,
          link: catalogRoute,
        },
        {
          label: `New ${toLower(labelEventType)}`,
        },
      ]}
    />
  );
};

export default EventTypeCreate;
