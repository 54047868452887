import * as browserRum from '@datadog/browser-rum';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { Redirect } from 'react-router';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import { NotificationCenter } from 'app/notifications';
import ConsentNoticeModal from 'app/notifications/components/ConsentNoticeModal/index.ts';
import { BrowserBackStackContext, useBrowserBackStack, useCurrentUser } from 'app/shared/hooks';
import ArticleDetail from 'features/articles/pages/ArticleDetail';
import ChannelAddContent from 'features/channels/pages/ChannelAddContent';
import ChannelCreate from 'features/channels/pages/ChannelCreate';
import ChannelDetail from 'features/channels/pages/ChannelDetail';
import ChannelEdit from 'features/channels/pages/ChannelEdit';
import Catalog from 'features/contentitems/pages/Catalog';
import Events from 'features/events/pages/Events';
import MyUpcoming from 'features/my_upcoming/pages/MyUpcoming';
import MyHub from 'features/myhub/pages/MyHub';
import PeopleDirectory from 'features/users/pages/PeopleDirectory';
import FailedAuthenticationModal from 'notifications/components/FailedAuthenticationModal';
import { MENTORSHIP_REQUEST_SENT, MENTORSHIP_REQUEST_RECEIVED } from 'program/constants';
import ArticleCreate from 'scenes/ArticlePage/ArticleCreate';
import ArticleEdit from 'scenes/ArticlePage/ArticleEdit';
import AutomatedRuleCreate from 'scenes/AutomatedRuleCreate';
import AutomatedRuleEdit from 'scenes/AutomatedRuleEdit';
import CodelabDetail from 'scenes/CodelabDetail';
import CodelabEdit from 'scenes/CodelabPage/CodelabEdit';
import CourseDetail from 'scenes/CourseDetail';
import CourseEdit from 'scenes/CoursePage/CourseEdit';
import EventDetail from 'scenes/EventDetail/EventDetail';
import EventCreate from 'scenes/EventPage/EventCreate';
import EventEdit from 'scenes/EventPage/EventEdit';
import EventTypeCreate from 'scenes/EventTypePage/EventTypeCreate';
import EventTypeEdit from 'scenes/EventTypePage/EventTypeEdit';
import ProgramSessionsFilter from 'scenes/ProgramSessionsFilter/ProgramSessionsFilter';
import ScheduledTrackEdit from 'scenes/ScheduledTrackPage/ScheduledTrackEdit';
import AssignmentReport from 'scenes/Segment/AssignmentReport';
import EnrollmentReport from 'scenes/Segment/EnrollmentReport';
import EventReport from 'scenes/Segment/EventReport';
import PeopleReport from 'scenes/Segment/PeopleReport';
import SessionMentorshipCreate from 'scenes/SessionMentorshipPage/SessionMentorshipCreate';
import SessionMentorshipEdit from 'scenes/SessionMentorshipPage/SessionMentorshipEdit';
import SessionProgramEdit from 'scenes/SessionProgramPage/SessionProgramEdit';
import { TaskCreate, TaskEdit } from 'scenes/TaskPage';
import TrackCreate from 'scenes/TrackPage/TrackCreate';
import TrackDetail from 'scenes/TrackPage/TrackDetail';
import TrackEdit from 'scenes/TrackPage/TrackEdit';
import VideoCreate from 'scenes/VideoPage/VideoCreate';
import VideoEdit from 'scenes/VideoPage/VideoEdit';
import { mapRoute } from 'services/requests';
import styledTheme from 'services/styled-theme';
import { lazyWithRetry } from 'services/utils';
import { ErrorBoundary, ScrollToTop } from 'shared';
import Loading from 'shared/components/Loading';
import RemoveDjangoApp from 'shared/components/RemoveDjangoApp';
import store from 'store';
import { CurrentUser } from 'users';
import { get, omit, isArray } from 'vendor/lodash';

import Page404 from './404';
import AlertPage from './AlertPage';
import Announcements from './Announcements';
import ApplicationToggles from './ApplicationToggles';
import AssessmentCreate from './AssessmentPage/AssessmentCreate';
import AssessmentDetail from './AssessmentPage/AssessmentDetail';
import AssessmentEdit from './AssessmentPage/AssessmentEdit';
import BulkAddUsersButton from './BulkAddUsersButton';
import Checkins from './Checkins';
import ContentItemAssignments from './ContentItemAssignments/ContentItemAssignments';
import CourseImport from './CourseImport';
import EventEnrollments from './EventEnrollments';
import EventsMissingFeedback from './EventsMissingFeedback';
import EventTypeBulkImport from './EventTypeBulkImport';
import EventTypeDetail from './EventTypeDetail';
import GoogleCalendarEventAuthChecker from './GoogleCalendarEventAuthChecker';
import GroupCreate from './GroupCreate';
import GroupDetail from './GroupDetail';
import GroupEdit from './GroupEdit';
import LinkedContentDetail from './LinkedContentDetail';
import LinkedContentCreate from './LinkedContentPage/LinkedContentCreate';
import LinkedContentEdit from './LinkedContentPage/LinkedContentEdit';
import MenteeFilter from './MenteeFilter';
import MentorFilter from './MentorFilter';
import MentorshipProgramCreate from './MentorshipProgramPage/MentorshipProgramCreate';
import MentorshipProgramDetail from './MentorshipProgramPage/MentorshipProgramDetail';
import MentorshipProgramEdit from './MentorshipProgramPage/MentorshipProgramEdit';
import MentorshipProgramSessionCreate from './MentorshipProgramPage/MentorshipProgramSessionCreate';
import MuiThemeWrapper from './MuiThemeWrapper';
import MyEvents from './MyEvents';
import MyGroups from './MyGroups';
import MyLearningContent from './MyLearningContent';
import MyMentorshipRequests from './MyMentorshipRequests';
import MySessions from './MySessions';
import MyTeam from './MyTeam';
import NavBar from './NavBar';
import PageWrapper from './PageWrapper';
import Poster from './Poster';
import ProgramCreate from './ProgramPage/ProgramCreate';
import ProgramEdit from './ProgramPage/ProgramEdit';
import ManageProgramMentees from './ProgramParticipants/ManageProgramMentees';
import ManageProgramMentors from './ProgramParticipants/ManageProgramMentors';
import ScheduledTrackCreate from './ScheduledTrackPage/ScheduledTrackCreate';
import ScheduledTrackDetail from './ScheduledTrackPage/ScheduledTrackDetail';
import SessionProgramCreate from './SessionProgramPage/SessionProgramCreate';
import SurveyAnswer from './SurveyAnswer';
import SurveyCreate from './SurveyCreate';
import SurveyEdit from './SurveyEdit';
import SurveyInsights from './SurveyInsights';
import SurveyPreview from './SurveyPreview';
import SystemSettings from './SystemSettings';
import TeamAssignments from './TeamAssignments/TeamAssignments.tsx';
import UserAddButton from './UserAddButton';
import UserEdit from './UserEdit';
import UserProfile from './UserProfile';
import VideoDetail from './VideoDetail';

const GlobalStyle = createGlobalStyle`
  body *:focus {
    ${({ theme }) => theme.mixins.outlineStyle}
  }
  [data-whatintent='mouse'] *:focus {
    outline: none !important;
  }
`;

const Header = React.memo((props) => {
  const currentUser = useCurrentUser();

  React.useEffect(() => {
    if (currentUser.id) {
      browserRum.datadogRum.setUser({
        id: currentUser.id,
        name: currentUser.name,
        tenant: window.CUSTOMER.schema_name,
      });
      browserRum.datadogRum.startSessionReplayRecording();
    }
  }, [currentUser]);

  return (
    <div className="site-header">
      <NavBar {...props} />
      <Announcements />
    </div>
  );
});

const LazyCharts = lazyWithRetry(() => import(/* webpackChunkName: "Charts" */ './Charts'));
const LazyDashboard = lazyWithRetry(() =>
  import(/* webpackChunkName: "Dashboard" */ './Dashboard')
);
const LazyContentItemsChartsRouting = lazyWithRetry(() =>
  import(/* webpackChunkName: "ContentItemsCharts" */ './Charts/ContentItems')
);
const LazyMentorshipsChartsRouting = lazyWithRetry(() =>
  import(/* webpackChunkName: "MentorshipsCharts" */ './Charts/MentorshipsCharts')
);
const LazyEventsChartsRouting = lazyWithRetry(() =>
  import(/* webpackChunkName: "EventsCharts" */ './Charts/EventsCharts')
);
const LazyProgramsChartsRouting = lazyWithRetry(() =>
  import(/* webpackChunkName: "ProgramsCharts" */ './Charts/ProgramsCharts')
);
const LazyUserEngagementChartsRouting = lazyWithRetry(() =>
  import(/* webpackChunkName: "UserEngagementCharts" */ './Charts/UserEngagementCharts')
);

const LastLocationProvider = ({ children }) => {
  const browserBackStack = useBrowserBackStack();
  return (
    <BrowserBackStackContext.Provider value={{ browserBackStack }}>
      {children}
    </BrowserBackStackContext.Provider>
  );
};

LastLocationProvider.propTypes = {
  children: PropTypes.node,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// eslint-disable-next-line no-unused-vars
const ReactApp = (props) => (
  <ThemeProvider theme={styledTheme}>
    <MuiThemeWrapper>
      <ErrorBoundary>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <Router>
              <LastLocationProvider>
                <ScrollToTop>
                  <div className="react-app-container">
                    <RemoveDjangoApp />
                    <NotificationCenter />
                    <CurrentUser />
                    <FailedAuthenticationModal />
                    <ConsentNoticeModal />
                    <GlobalStyle />
                    <div className="react-app-navbar">
                      <Route component={Header} />
                    </div>
                    <PageWrapper>
                      <GoogleCalendarEventAuthChecker />
                      <Suspense fallback={<Loading />}>
                        <Switch>
                          <Route exact path="/checkin/" component={Checkins} />
                          <Route exact path="/feedback/" component={EventsMissingFeedback} />

                          <Route exact path="/a/tracks/create/" component={TrackCreate} />
                          <Route
                            exact
                            path="/a/tracks/:public_id_and_slug([\w-]+)/"
                            component={TrackDetail}
                          />
                          <Route
                            exact
                            path="/a/dashboard/event/report/:public_id_and_slug([\w-]+)/"
                            component={EventReport}
                          />
                          <Route
                            exact
                            path="/a/dashboard/assignment/report/:public_id_and_slug([\w-]+)/"
                            component={AssignmentReport}
                          />
                          <Route
                            exact
                            path="/a/dashboard/people/report/:public_id_and_slug([\w-]+)/"
                            component={PeopleReport}
                          />
                          <Route
                            exact
                            path="/a/dashboard/enrollment/report/:public_id_and_slug([\w-]+)/"
                            component={EnrollmentReport}
                          />
                          <Route
                            exact
                            path="/a/tracks/:public_id([\w-]+)/edit"
                            component={TrackEdit}
                          />

                          <Route
                            exact
                            path="/a/scheduled-tracks/create/"
                            component={ScheduledTrackCreate}
                          />
                          <Route
                            exact
                            path="/a/scheduled-tracks/:public_id_and_slug([\w-]+)/"
                            component={ScheduledTrackDetail}
                          />
                          <Route
                            exact
                            path="/a/scheduled-tracks/:public_id([\w-]+)/edit/"
                            component={ScheduledTrackEdit}
                          />

                          <Route exact path="/a/assessments/create/" component={AssessmentCreate} />
                          <Route
                            exact
                            path="/a/assessments/:public_id_and_slug([\w-]+)/"
                            component={AssessmentDetail}
                          />
                          <Route
                            exact
                            path="/a/assessments/:public_id([\w-]+)/edit"
                            component={AssessmentEdit}
                          />

                          <Route
                            exact
                            path="/a/system-settings/:context([\w-]+)/"
                            component={SystemSettings}
                          />

                          <Route
                            exact
                            path="/a/application-toggles/"
                            component={ApplicationToggles}
                          />

                          <Route exact path="/events/" component={Events} />
                          <Route exact path="/a/my-upcoming/" component={MyUpcoming} />
                          <Route
                            exact
                            path="/events/:public_id_and_slug([\w-]+)/"
                            component={EventDetail}
                          />

                          <Route
                            exact
                            path="/a/events/:public_id_and_slug([\w-]+)/edit/"
                            component={EventEdit}
                          />

                          <Route exact path="/a/events/new/" component={EventCreate} />

                          <Route exact path="/events/topic/:slug([\w-]+)/" component={Events} />

                          <Route
                            exact
                            path="/a/events/:public_id_and_slug([\w-]+)/attendance/"
                            component={EventEnrollments}
                          />
                          <Route exact path="/a/surveys/:id(\d+)/" component={SurveyAnswer} />
                          <Route
                            exact
                            path="/a/surveys/:id(\d+)/preview/"
                            component={SurveyPreview}
                          />
                          <Route
                            exact
                            path="/a/surveys/:id(\d+)/insights/"
                            component={SurveyInsights}
                          />
                          <Route exact path="/a/surveys/:id(\d+)/edit/" component={SurveyEdit} />
                          <Route exact path="/a/surveys/create/" component={SurveyCreate} />

                          <Route exact path="/a/groups/:id(\d+)/" component={GroupDetail} />
                          <Route exact path="/a/groups/create/" component={GroupCreate} />
                          <Route exact path="/a/groups/:id(\d+)/edit/" component={GroupEdit} />

                          <Route exact path="/a/mentors/" component={MentorFilter} />
                          <Route exact path="/a/mentees/" component={MenteeFilter} />
                          <Route exact path="/a/connections/" component={PeopleDirectory} />
                          <Route exact path="/a/myhub/" component={MyHub} />

                          <Route
                            exact
                            path="/a/sessions/mentorship/create/"
                            component={SessionMentorshipCreate}
                          />
                          <Route
                            exact
                            path="/a/sessions/mentorship/:public_id/edit/"
                            component={SessionMentorshipEdit}
                          />
                          <Route
                            exact
                            path="/a/sessions/create/"
                            component={SessionProgramCreate}
                          />
                          <Route
                            exact
                            path="/a/sessions/:public_id/edit/"
                            component={SessionProgramEdit}
                          />
                          <Route exact path="/a/programs/create/" component={ProgramCreate} />
                          <Route
                            exact
                            path="/a/mentorship_programs/create/"
                            component={MentorshipProgramCreate}
                          />
                          <Route
                            exact
                            path="/a/programs/:public_id_and_slug([\w-]+)/edit/"
                            component={ProgramEdit}
                          />
                          <Route
                            exact
                            path="/a/mentorship_programs/:public_id_and_slug([\w-]+)/edit/"
                            component={MentorshipProgramEdit}
                          />
                          <Route
                            exact
                            path="/a/programs/:public_id_and_slug([\w-]+)"
                            component={ProgramSessionsFilter}
                          />
                          <Route
                            exact
                            path="/a/mentorship_programs/:public_id_and_slug([\w-]+)"
                            component={MentorshipProgramDetail}
                          />
                          <Route
                            exact
                            path="/a/mentorship_programs/:public_id_and_slug([\w-]+)/sessions/create"
                            component={MentorshipProgramSessionCreate}
                          />
                          <Route
                            exact
                            path="/a/mentorship_programs/:public_id_and_slug([\w-]+)/mentors/"
                            component={ManageProgramMentors}
                          />
                          <Route
                            exact
                            path="/a/mentorship_programs/:public_id_and_slug([\w-]+)/mentees/"
                            component={ManageProgramMentees}
                          />
                          <Route exact path="/a/my-events/" component={MyEvents} />
                          <Route exact path="/a/my-sessions/" component={MySessions} />
                          <Route
                            exact
                            path="/a/my-mentorship-requests/received/"
                            render={(routeProps) => (
                              <MyMentorshipRequests
                                {...routeProps}
                                viewMode={MENTORSHIP_REQUEST_RECEIVED}
                              />
                            )}
                          />
                          <Route
                            exact
                            path="/a/my-mentorship-requests/sent/"
                            render={(routeProps) => (
                              <MyMentorshipRequests
                                {...routeProps}
                                viewMode={MENTORSHIP_REQUEST_SENT}
                              />
                            )}
                          />
                          <Route
                            exact
                            path="/a/my-learning-content/"
                            component={MyLearningContent}
                          />
                          <Route exact path="/a/my-groups/" component={MyGroups} />

                          <Route exact path="/a/content/" component={Catalog} />

                          <Route exact path="/a/my-team/" component={MyTeam} />
                          <Route exact path="/a/team-assignments/" component={TeamAssignments} />

                          <Route
                            exact
                            path="/event-types/:public_id_and_slug([\w-]+)/"
                            component={EventTypeDetail}
                          />
                          <Route exact path="/a/event-types/create/" component={EventTypeCreate} />
                          <Route
                            exact
                            path="/a/event-types/:public_id_and_slug([\w-]+)/edit/"
                            component={EventTypeEdit}
                          />
                          <Route
                            exact
                            path="/a/event-types/bulk-import/"
                            component={EventTypeBulkImport}
                          />

                          <Route path="/users/:id(\d+)/" component={UserProfile} />
                          <Route exact path="/a/users/:id(\d+)/edit/" component={UserEdit} />

                          <Route path="/a/notifications/alerts/" component={AlertPage} />
                          <Route path="/a/dashboard" component={LazyDashboard} />
                          <Route path="/a/charts" component={LazyCharts} />
                          <Route
                            path="/a/charts-content-item"
                            component={LazyContentItemsChartsRouting}
                          />
                          <Route
                            path="/a/charts-mentorship"
                            component={LazyMentorshipsChartsRouting}
                          />
                          <Route path="/a/charts-events" component={LazyEventsChartsRouting} />
                          <Route path="/a/charts-programs" component={LazyProgramsChartsRouting} />
                          <Route
                            path="/a/charts-engagement"
                            component={LazyUserEngagementChartsRouting}
                          />

                          <Route exact path="/a/articles/create/" component={ArticleCreate} />
                          <Route
                            exact
                            path="/a/articles/:public_id/edit/"
                            component={ArticleEdit}
                          />

                          <Route
                            exact
                            path="/a/linkedcontent/create/"
                            component={LinkedContentCreate}
                          />
                          <Route
                            exact
                            path="/a/linkedcontent/:public_id/edit/"
                            component={LinkedContentEdit}
                          />

                          <Route exact path="/a/tasks/create/" component={TaskCreate} />
                          <Route exact path="/a/tasks/:public_id/edit/" component={TaskEdit} />

                          <Route
                            exact
                            path="/a/codelabs/:public_id_and_slug/edit/"
                            component={CodelabEdit}
                          />

                          <Route exact path="/a/courses/import/" component={CourseImport} />
                          <Route
                            exact
                            path="/a/courses/:public_id_and_slug/edit/"
                            component={CourseEdit}
                          />

                          <Route exact path="/a/videos/create/" component={VideoCreate} />
                          <Route exact path="/a/videos/:public_id/edit/" component={VideoEdit} />

                          <Route
                            exact
                            path="/a/contents/:public_id_and_slug([\w-]+)/assignments"
                            component={ContentItemAssignments}
                          />

                          <Route
                            exact
                            path="/a/videos/:public_id_and_slug([\w-]+)"
                            component={VideoDetail}
                          />
                          <Route
                            exact
                            path="/a/codelabs/:public_id_and_slug"
                            component={CodelabDetail}
                          />
                          <Route
                            exact
                            path="/a/courses/:public_id_and_slug"
                            component={CourseDetail}
                          />
                          <Route
                            exact
                            path="/a/articles/:public_id_and_slug([\w-]+)"
                            component={ArticleDetail}
                          />
                          <Route
                            exact
                            path="/a/linkedcontent/:public_id_and_slug([\w-]+)"
                            component={LinkedContentDetail}
                          />

                          <Route
                            path="/a/automated-rules/create/"
                            component={AutomatedRuleCreate}
                          />
                          <Route
                            path="/a/automated-rules/:public_id/edit/"
                            component={AutomatedRuleEdit}
                          />

                          <Route exact path="/a/channels/create/" component={ChannelCreate} />
                          <Route
                            exact
                            path="/a/channels/:public_id_and_slug([\w-]+)/edit/"
                            component={ChannelEdit}
                          />
                          <Route
                            exact
                            path="/a/channels/:public_id_and_slug([\w-]+)"
                            component={ChannelDetail}
                          />
                          <Route
                            exact
                            path="/a/channels/:public_id_and_slug([\w-]+)/add-content/"
                            component={ChannelAddContent}
                          />

                          {/* -------- Django Pages --------- */}
                          <Route path="/organic-power-snacks" component={null} />
                          <Route path="/events/:public_id([\w-]+)/delete/" component={null} />
                          <Route path="/events/:public_id([\w-]+)/resources/" component={null} />
                          <Route path="/users/:id(\d+)/delete/" component={null} />
                          <Route path="/backoffice" component={null} />
                          <Route path="/login" component={null} />
                          {/* -------- /Django Pages --------- */}

                          {/* -------- Redirects --------- */}
                          <Redirect from="/a/people/" to="/a/connections/" />
                          <Redirect from="/a/catalog/" to="/a/content/" />
                          <Redirect from="/a/unified-catalog/" to="/a/content/" />
                          {/* Reports redirects */}
                          <Route
                            path="/a/reports-mentorship/"
                            render={({ location }) => (
                              <Redirect
                                to={{ pathname: '/a/charts-mentorship/', search: location.search }}
                              />
                            )}
                          />
                          <Route
                            path="/a/reports-events/"
                            render={({ location }) => (
                              <Redirect
                                to={{ pathname: '/a/charts-events/', search: location.search }}
                              />
                            )}
                          />
                          <Route
                            path="/a/reports-programs/"
                            render={({ location }) => (
                              <Redirect
                                to={{ pathname: '/a/charts-programs/', search: location.search }}
                              />
                            )}
                          />
                          <Route
                            path="/a/reports-engagement/"
                            render={({ location }) => (
                              <Redirect
                                to={{ pathname: '/a/charts-engagement/', search: location.search }}
                              />
                            )}
                          />
                          <Route
                            path="/a/reports/event"
                            render={({ location }) => (
                              <Redirect
                                to={{ pathname: '/a/charts/event', search: location.search }}
                              />
                            )}
                          />
                          <Route
                            path="/a/reports/user-engagement"
                            render={({ location }) => (
                              <Redirect
                                to={{
                                  pathname: '/a/charts/user-engagement',
                                  search: location.search,
                                }}
                              />
                            )}
                          />
                          <Route
                            path="/a/reports/program"
                            render={({ location }) => (
                              <Redirect
                                to={{ pathname: '/a/charts/program', search: location.search }}
                              />
                            )}
                          />
                          <Route
                            path="/a/coaching/:public_id([\w-]+)/"
                            render={({ match }) => (
                              <Redirect to={`/a/sessions/?program=${match.params.public_id}`} />
                            )}
                          />
                          <Route
                            path="/a/coaching/sessions/"
                            render={({ location }) => (
                              <Redirect
                                to={{ pathname: '/a/sessions/', search: location.search }}
                              />
                            )}
                          />
                          <Redirect from="/a/coaching/sessions/create/" to="/a/sessions/create/" />
                          <Redirect
                            from="/a/coaching/sessions/:id(\d+)/edit/"
                            to="/a/sessions/:id(\d+)/edit/"
                          />
                          <Redirect from="/a/coaching/my-sessions/" to="/a/my-sessions/" />
                          <Redirect from="/a/my-tracks/" to="/a/my-learning-content/" />
                          <Redirect
                            from="/a/coaching/program/:public_id_and_slug([\w-]+)/edit/"
                            to="/a/programs/program/:public_id_and_slug([\w-]+)/edit/"
                          />
                          <Redirect
                            from="/a/coaching/program/create/"
                            to="/a/programs/program/create/"
                          />
                          <Redirect from="/a/coaching/" to="/a/programs/" />
                          <Route
                            path="/a/programs/"
                            render={() => {
                              const redirectionRoute = `${mapRoute(
                                'unifiedCatalogList'
                              )}?eq(type,programs)&ordering(relevance)`;

                              return <Redirect to={redirectionRoute} />;
                            }}
                          />

                          <Redirect from="/a/programs/program/create/" to="/a/programs/create/" />
                          <Redirect
                            from="/a/programs/program/:public_id_and_slug([\w-]+)/edit/"
                            to="/a/programs/:public_id_and_slug/edit/"
                          />

                          <Route
                            path="/a/sessions/"
                            render={({ location }) => {
                              let redirectionRoute = mapRoute('unifiedCatalogList');
                              const params = queryString.parse(location.search);
                              const program = get(params, 'program', '');
                              const programId = isArray(program) ? program[0] : program;

                              if (programId) {
                                const filters = omit(params, ['program']);
                                redirectionRoute = `${mapRoute('programDetails', {
                                  // This will work with both public_id and public_id_and_slug variations
                                  public_id_and_slug: programId,
                                })}?${queryString.stringify(filters)}`;
                              }

                              return <Redirect to={redirectionRoute} />;
                            }}
                          />
                          <Redirect
                            from="/a/stand-alone/:type/:id"
                            to="/a/:type(articles|courses)/:id"
                          />
                          {/* -------- /Redirects --------- */}

                          <Route component={Page404} />
                        </Switch>
                      </Suspense>
                    </PageWrapper>
                  </div>
                </ScrollToTop>
              </LastLocationProvider>
            </Router>
          </QueryClientProvider>
        </Provider>
      </ErrorBoundary>
    </MuiThemeWrapper>
  </ThemeProvider>
);

export function UserAddButtonContainer() {
  return (
    <Provider store={store}>
      <div className="add-user-buttons-container">
        <UserAddButton />
        <BulkAddUsersButton />
      </div>
    </Provider>
  );
}

export function PosterContainer() {
  return (
    <Provider store={store}>
      <Router>
        <Poster />
      </Router>
    </Provider>
  );
}

const app = {
  Container: null,
  elementId: '',
};

if (document.getElementById('poster-app')) {
  app.Container = PosterContainer;
  app.elementId = 'poster-app';
}

if (document.getElementById('user-add-button')) {
  app.Container = UserAddButtonContainer;
  app.elementId = 'user-add-button';
}

const { Container, elementId } = app;

export { Container, elementId };
export default ReactApp;
