import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { INITIAL_DURATIONS } from 'app/shared/constants';
import { toast } from 'notifications/components/NotificationCenter';
import ProgramForm from 'program/components/ProgramForm';
import { mapRoute } from 'services/requests';
import PageTitle from 'shared/components/PageTitle/PageTitle';
import { useCurrentUser } from 'shared/hooks';
import { sortCustomTags } from 'topics/services';
import { has, isEmpty } from 'vendor/lodash';

const getInitialValues = ({ currentUser, program }) => {
  const initialValues = {
    ...program,
  };

  // Facilitators
  if (!has(initialValues, 'facilitators_ids')) {
    initialValues.facilitators_ids = [currentUser.id];
  }

  // Access control
  if (!has(initialValues, 'is_hidden')) {
    initialValues.is_hidden = false;
  }

  // Default duration for Program is 30min
  if (!has(initialValues, 'duration')) {
    initialValues.duration = INITIAL_DURATIONS.program;
  }

  const customTagsInitialValues = sortCustomTags(currentUser, initialValues, 'tags');

  return { ...initialValues, ...customTagsInitialValues };
};

const ProgramFormPage = ({
  pageTitle,
  topBarActionName,
  backRoute,
  breadcrumbsItemList,
  initialValuesParams,
  initialValuesParams: { program },
}) => {
  const currentUser = useCurrentUser();
  const history = useHistory();

  const initialValuesState = useState(
    getInitialValues({
      currentUser,
      ...initialValuesParams,
    })
  );

  const initialValues = initialValuesState[0];

  if (!isEmpty(initialValuesParams?.settings)) {
    initialValues.settings = initialValuesParams?.settings;
  }

  const isEdit = Boolean(program);
  const form = `newProgram${isEdit ? 'Edit' : 'Create'}Form`;

  return (
    <>
      <PageTitle title={pageTitle} />
      <ProgramForm
        form={form}
        settingsContext="office_hours_program/form"
        currentUser={currentUser}
        initialValues={initialValues}
        topBarActionName={topBarActionName}
        isEdit={isEdit}
        backRoute={backRoute}
        breadcrumbsItemList={breadcrumbsItemList}
        onSubmitSuccessHandler={(result) => {
          const { public_id_and_slug } = result;
          history.push(mapRoute('programDetails', { public_id_and_slug }));
          toast.success('Content added successfully!');
        }}
      />
    </>
  );
};

export default ProgramFormPage;

ProgramFormPage.defaultProps = {
  initialValuesParams: {},
};

ProgramFormPage.propTypes = {
  pageTitle: PropTypes.string,
  topBarActionName: PropTypes.string,
  backRoute: PropTypes.string,
  breadcrumbsItemList: PropTypes.arrayOf(PropTypes.object),
  initialValuesParams: PropTypes.object,
};
