import React from 'react';

import { LEARNING_TYPES } from 'catalog/constants';
import { mapRoute } from 'services/requests';
import { useParentSettings } from 'settings/hooks';
import { useOriginRoute, useSetDetailedObject, useLabels } from 'shared/hooks';
import { toLower } from 'vendor/lodash';

import ProgramFormPage from './ProgramFormPage';

const ProgramCreate = () => {
  const { label_program: labelProgram, label_program_plural: labelProgramPlural } = useLabels();

  const catalogRoute = `${mapRoute('unifiedCatalogList')}?learning_types=${
    LEARNING_TYPES.programs
  }&o=upcoming`;
  const backRoute = useOriginRoute(catalogRoute);
  const parentSettingsFlat = useParentSettings('office_hours_program/form', 'flat');

  useSetDetailedObject({ id: null }, 'programs', []);

  const initialValuesParams = {
    settings: parentSettingsFlat,
  };

  return (
    <ProgramFormPage
      pageTitle={`Create ${labelProgram}`}
      topBarActionName={`Creating ${labelProgram}`}
      backRoute={backRoute}
      initialValuesParams={initialValuesParams}
      breadcrumbsItemList={[
        {
          label: labelProgramPlural,
          link: catalogRoute,
        },
        {
          label: `New ${toLower(labelProgram)}`,
        },
      ]}
    />
  );
};

export default ProgramCreate;
