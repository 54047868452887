import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import CoverImageField from 'inputs/components/CoverImageField';
import FacilitatorField from 'inputs/components/FacilitatorField';
import InputLabel from 'inputs/components/InputLabel';
import TextEditorField, { TextEditorContainer } from 'inputs/components/OldTextEditorField';
import TextField from 'inputs/components/TextField';
import { toast } from 'notifications/components/NotificationCenter';
import { FormFieldGroup } from 'shared/components/Form';
import NewContentForm from 'shared/components/NewContentForm';
import Form from 'shared/components/OldForm';
import { useFormSelector, useFormPreventTransition } from 'shared/hooks';
import { toLower, pick } from 'vendor/lodash';

type ChannelFormProps = {
  form: string;

  // Redux Form props
  invalid: boolean;
  error: object;
  submitting: boolean;
  change: (field: string, value: any) => void;
  handleSubmit: (event: any) => void;

  isEdit: boolean;

  breadcrumbsItemList: object[];

  topBarActionName: string;
  backRoute: string;
};

const CoverContainer = styled.div`
  display: flex;
`;

const validateRequired = Form.validations.required();

export const ChannelForm = (props: ChannelFormProps) => {
  const {
    form,
    invalid,
    error,
    submitting,
    change,
    handleSubmit,
    isEdit,
    breadcrumbsItemList,
    topBarActionName,
    backRoute,
  } = props;

  const labelChannel = 'Channel';
  const labelChannelPlural = 'Channels';

  const infoPanelText = `${labelChannelPlural} gives capacity for displaying team-specific content`;

  useFormPreventTransition(form);

  const channelName = useFormSelector(form, 'name');

  // TODO - add HC article URL
  const HCArticleURL = undefined;

  const channelSectionList = [
    {
      id: 'channel-details',
      label: 'Details',
      icon: 'info',
      section: (
        <>
          <FormFieldGroup>
            <Field
              label="Title"
              name="name"
              required
              component={TextField}
              placeholder={`Give your ${toLower(labelChannel)} a title`}
              validate={[validateRequired]}
            />
          </FormFieldGroup>
          <FormFieldGroup>
            <CoverContainer>
              <Field
                name="cover"
                component={CoverImageField}
                imageWidth="412px"
                imageHeight="231px"
                filePath="video_covers"
              />
            </CoverContainer>
          </FormFieldGroup>
          <FormFieldGroup>
            <InputLabel htmlFor="content_body">Description</InputLabel>
            <TextEditorContainer>
              <Field
                name="content_body"
                component={TextEditorField}
                allowGenericLinks
                allowImageButton
                allowCloudDocButton
                allowVideoButton
              />
            </TextEditorContainer>
          </FormFieldGroup>
        </>
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
    {
      id: 'people',
      label: 'People',
      icon: 'persons',
      section: (
        <FacilitatorField
          label="Owner(s)"
          infoText="People who can make Settings changes including privileges and subscriptions."
          name="owners_ids"
        />
      ),
      sectionProps: {
        defaultOpen: true,
      },
    },
  ];

  const advancedSettingsList = [];

  return (
    <NewContentForm
      contentNameSingular={`${labelChannel} settings`}
      contentInfoPanelText={infoPanelText}
      contentHCArticleURL={HCArticleURL}
      invalid={invalid}
      handleSubmit={handleSubmit}
      error={error}
      change={change}
      submitting={submitting}
      contentTitle={channelName}
      topBarActionName={topBarActionName}
      isEdit={isEdit}
      backRoute={backRoute}
      breadcrumbsItemList={breadcrumbsItemList}
      contentSectionsList={channelSectionList}
      advancedSettingsList={advancedSettingsList}
      isModalForm={false}
    />
  );
};

const ConnectedChannelForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmit: async (values, _dispatch, { onSubmitHandler }) => {
    const payload = {
      ...pick(values, ['name', 'content_body', 'cover', 'owners_ids']),
    };

    return await onSubmitHandler(payload);
  },
  onSubmitSuccess: (result, _dispatch, { onSubmitSuccessHandler, isEdit }) => {
    onSubmitSuccessHandler(result, isEdit);
  },
  onSubmitFail: () => {
    window.scrollTo(0, 0);
    toast.error('Content submission failed. Check the flagged fields and try again.');
  },
})(ChannelForm);

export default ConnectedChannelForm;
