import {
  CardBase,
  CardBody,
  CardCover,
  CardDetail,
  CardFooter,
  CardLocation,
} from 'common/components/cards';
import { Session } from 'common/types';
import TimeslotCard from 'event-shared/components/TimeslotCard';
import SessionCardActions from 'features/sessions/components/SessionCardActions';
import {
  getAttendanceSessionInfo,
  getCurrentUserSessionInfo,
  useDeleteSessionFromCache,
} from 'features/sessions/helpers';
import SessionModalManager, {
  useSessionModalManagerState,
} from 'program/components/SessionModalManager';
import { sessionStartsAtHasPassed, useSessionEnrollmentManager } from 'program/services';
import { SimpleCardHeader } from 'shared-cards';
import DurationAndTags from 'shared-cards/components/DurationAndTags';
import { useCurrentUser } from 'shared/hooks';
import { get, toInteger, isInteger, map } from 'vendor/lodash';
import { Typography } from 'vendor/mui';

interface Props {
  session: Session;
}

const SessionCard = ({ session }: Props) => {
  const currentUser = useCurrentUser();
  const { modalManagerState, modalManagerSetters, openDetailsModal, openRosterModal } =
    useSessionModalManagerState();
  const { deleteSession } = useDeleteSessionFromCache();
  const { dropSession, isLoading } = useSessionEnrollmentManager(session, {
    onDelete: () => deleteSession(session.id),
    onUnenroll: () => deleteSession(session.id),
  });
  const {
    name,
    cover_url: coverUrl,
    host,
    starts_at_tz_aware: startsAtTzAware,
    location_name: locationName,
    allows_online: isOnline,
    duration,
    program_id: programId,
    attendance_limit: attendanceLimit,
    enrollments,
    enrollments_count: enrollmentsCount,
    user_session_enrollment: enrollment,
  } = session;
  const hasProgram = isInteger(programId);
  const isUpcoming = !sessionStartsAtHasPassed(session, currentUser.timezone);
  const isAttendee = currentUser.id === get(enrollment, 'attendee_id');
  const isHost = currentUser.id === get(host, 'id');
  if (!isAttendee && !isHost) return null;
  return (
    <CardBase>
      <CardCover
        coverUrl={coverUrl}
        onClick={attendanceLimit === 1 ? openDetailsModal : openRosterModal}
      />
      <SimpleCardHeader
        contentTitle={name}
        creators={isAttendee ? [host] : map(enrollments, 'attendee')}
        emptyCreatorsMessage={hasProgram ? 'No attendee yet' : 'No mentee yet'}
        mode="card"
        onTitleClick={attendanceLimit === 1 ? openDetailsModal : openRosterModal}
      />
      <CardBody>
        <CardDetail>
          <CardLocation name={locationName} />
          <TimeslotCard
            timeslot={{ starts_at_tz_aware: startsAtTzAware }}
            locationTimezone={get(location, 'timezone')}
            userTimezone={currentUser.timezone}
            eventIsOnline={isOnline}
            wrapDatetime={false}
            marginLeftOutsideLearning={false}
          />
        </CardDetail>
        <DurationAndTags duration={duration} />
        <SessionCardActions session={session} dropSession={dropSession} isLoading={isLoading} />
      </CardBody>
      <CardFooter>
        <Typography variant="caption">
          {getAttendanceSessionInfo({
            hasProgram,
            isUpcoming,
            attendanceLimit,
            enrollmentsCount: toInteger(enrollmentsCount),
          })}
        </Typography>
        <Typography variant="caption">
          {getCurrentUserSessionInfo({
            isUpcoming,
            isAttendee,
            isHost,
            attendanceLimit,
            enrollmentsCount: toInteger(enrollmentsCount),
          })}
        </Typography>
      </CardFooter>
      <SessionModalManager
        session={session}
        state={modalManagerState}
        setters={modalManagerSetters}
        handleDropSession={dropSession}
      />
    </CardBase>
  );
};

export default SessionCard;
