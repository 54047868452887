import { useQuery } from '@tanstack/react-query';

import { queries } from 'queries';
import Page404 from 'scenes/404';
import Loading from 'shared/components/Loading';
import { usePublicIdFromURL } from 'shared/hooks';

import { ChannelAddContentPage } from '../components/ChannelAddContentPage';

const ChannelAddContent = () => {
  const { publicId: channelPublicId } = usePublicIdFromURL();

  const { data, isLoading, isError } = useQuery(queries.channels.detail(channelPublicId || ''));

  if (isError) return <Page404 />;

  if (isLoading) return <Loading />;

  return <ChannelAddContentPage channel={data} />;
};

export default ChannelAddContent;
